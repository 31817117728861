import React, { useEffect, useState, useContext } from 'react';
import useReactRouter from 'use-react-router';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { Accent, SchemaButton, Container, isAuthorizedFun, ContextStore } from 'Components';
import { Spin, Table, Button, notification } from 'antd';

import { collectionResource } from 'Resources';
import { humanizeKey } from 'Utility';
import { getMyEntries, getEntries, addEntry } from '../Beheer/Adapter';

export const CollectionOverview = () => {
  const { t } = useTranslation();
  const { state } = useContext(ContextStore);
  const collectionResources = collectionResource.read();
  const {
    match: { params },
  } = useReactRouter();

  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState({});
  const [visible, setVisible] = useState(false);

  const [collectionEntries, setCollectionEntries] = useState();

  // TODO get from router
  const id = params.collection;
  const selectedCollection = collectionResources.find(c => c.id === id);

  const handleSubmit = (collectionId, formData) => {
    setLoading(true);
    addEntry(collectionId, formData)
      .then(r => {
        setCollectionEntries([...collectionEntries, r.data]);
        setVisible(false);
        setFormState({});
        notification.success({
          message: t('handleAct.success.title'),
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getEntries(id).then(r => setCollectionEntries(r.data));
  }, [params.collection]);

  if (!selectedCollection || !collectionEntries) {
    return <Spin />;
  }

  const columns = [
    {
      title: t('form.identifier'),
      dataIndex: 'data.identifier',
    },
  ];

  columns.push({
    title: '',
    dataIndex: '',
    align: 'right',
    render: (text, record) => (
      <Link to={`/collections/${params.collection}/${record.id}`}>
        <Button icon="eye" type="primary" />
      </Link>
    ),
  });

  return (
    <Container>
      <Accent type="h1">{t(`entities.${id}_plural`, humanizeKey(id))}</Accent>
      <Table rowKey={r => r.id} columns={columns} dataSource={collectionEntries} />
      {isAuthorizedFun(state.myAccount, [{ type: 'organization', role: 'Admin' }]) && (
        <SchemaButton
          loading={loading}
          config={selectedCollection}
          onSubmit={handleSubmit}
          formState={formState}
          setFormState={setFormState}
          visible={visible}
          setVisible={setVisible}
        />
      )}
    </Container>
  );
};
