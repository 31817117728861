import React from 'react';
import { Modal } from 'antd';
import { CustomForm, Accent } from 'Components';
import { useTranslation } from 'react-i18next';

export const RegistrationModal = ({
  config,
  visible,
  formState,
  setFormState,
  setVisible,
  onSubmit,
  loading,
}) => {
  const clearState = () => {
    setVisible(false);
    setFormState({});
  };

  const { t } = useTranslation();

  return (
    <Modal visible={visible} destroyOnClose onCancel={clearState} footer={false}>
      <Accent type="h1"> {t(`add.${config.id}`)}</Accent>

      <CustomForm
        loading={loading}
        config={config}
        jsonschema={config.schema}
        onSubmit={({ formData }) => onSubmit(config.id, formData)}
        onCancel={clearState}
        formState={formState}
        setFormState={setFormState}
      />
    </Modal>
  );
};
