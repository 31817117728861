import React, { useContext } from 'react';
import { ContextStore } from 'Components';

export const GetAssetData = (assets, assetId) => {
  const asset = assets.find(x => x.id === assetId);
  if (assetId) return (assetId && asset.data.packingUnit) || asset.data.unit || '';

  return '';
};
export const GetAsset = (assets, assetId) => {
  const asset = assets.find(x => x.id === assetId);
  if (assetId) return asset;

  return undefined;
};

export const GetAssetDataComp = ({ batch }) => {
  const {
    state: { assets },
  } = useContext(ContextStore);

  const asset = assets.find(x => x.id === batch.assetId);
  return <span>{asset.data.packingUnit || asset.data.unit || ''}</span>;
};
