import React from 'react';
import styles from './grid.module.scss';

export const Container = ({ children, style, className }) => {
  const classNames = `${className || ''} ${styles.container}`;

  return (
    <div style={style} className={classNames}>
      {children}
    </div>
  );
};
