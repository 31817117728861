import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ContextStore, BatchCard, Accent } from 'Components';

import { getAssetConfigsOfType } from 'Utility';
import { Col, Row } from 'antd';
import { compareBatchesInGroup } from 'Utility/uiSettings';

export const BatchCardGrid = ({ batches }) => {
  const { t } = useTranslation();
  const {
    state: { assets, assetConfigs },
  } = useContext(ContextStore);
  const rootAssetIds = assetConfigs.filter(a => !a.parent).map(a => a.id);
  const batchesWithAmount =
    assets.length === 0 ? (
      <Col span={24}>Er zijn nog geen producten toegevoegd</Col>
    ) : (
      assetConfigs
        .filter(a => rootAssetIds.includes(a.parent))
        .map(a => {
          const childAssets = getAssetConfigsOfType(assetConfigs, a.id, true).map(x => x.id);
          const assetBatches = batches
            .filter(b => b.amount)
            .filter(b => {
              const asset = assets.find(ma => ma.id === b.assetId);
              return asset && childAssets.includes(asset.configId);
            })
            .sort((b1, b2) => compareBatchesInGroup(a, b1, b2))
            .map(b => <BatchCard key={b.id} batch={b} colSpan={8} />);

          if (assetBatches.length === 0) return null;

          return (
            <div key={a.id}>
              <Col span={24} style={{ paddingLeft: 20, paddingRight: 20 }}>
                <Accent type="h1">{t(`asset.${a.id}`, a.id)}</Accent>
              </Col>
              <Col span={24} style={{ padding: 0 }}>
                {assetBatches}
              </Col>
            </div>
          );
        })
    );

  const archivedBatches = batches
    .filter(b => !b.amount)
    .map(b => <BatchCard key={b.id} batch={b} colSpan={8} />);

  return (
    <Row gutter={20}>
      {batchesWithAmount}
      {!!archivedBatches.length && (
        <>
          <Col span={24} style={{ paddingLeft: 20, paddingRight: 20, marginTop: 50 }}>
            <Accent type="h1">{t(`archived.batches`)}</Accent>
          </Col>
          {archivedBatches}
        </>
      )}
    </Row>
  );
};
