import Axios from 'axios';
import { notification } from 'antd';
import { getActiveOrganization } from './helpers';

const accountsAxios = Axios.create({
  baseURL: window.ACCOUNTS_BASE_URL,
});

const supplychainAxios = Axios.create({
  baseURL: window.SUPPLYCHAIN_BASE_URL,
});
const storageAxios = Axios.create({
  baseURL: window.STORAGE_API_URL,
});

/**
 *
 * @param {import('keycloak-js').KeycloakInstance} keycloak
 */
export async function setupAxiosWithKeycloak(keycloak) {
  const interceptor = config =>
    new Promise(resolve =>
      keycloak
        .updateToken(5)
        .success(() => {
          config.headers.Authorization = `Bearer ${keycloak.token}`;
          resolve(config);
        })
        .error(() => {
          keycloak.login();
        })
    );

  supplychainAxios.interceptors.request.use(interceptor);
  storageAxios.interceptors.request.use(interceptor);
  accountsAxios.interceptors.request.use(interceptor);
}

const organizationInterceptor = config => {
  config.headers.common['X-Organization'] = getActiveOrganization();
  return config;
};
supplychainAxios.interceptors.request.use(organizationInterceptor);
// accountsAxios.interceptors.request.use(organizationInterceptor);

const errorResponseInterceptor = error => {
  // Do something with response error
  let errorText = 'Onbekende fout';
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    errorText = error.response.data;
  } else if (error.request) {
    errorText = 'Er gaat iets fout in de request, zie console voor meer informatie';
  } else {
    errorText = error.message;
  }
  notification.error({ message: 'Er gaat iets fout', description: errorText });
  return Promise.reject(error);
};

// Add a response interceptor
accountsAxios.interceptors.response.use(
  response => response,
  error => errorResponseInterceptor(error)
);
supplychainAxios.interceptors.response.use(
  response => response,
  error => errorResponseInterceptor(error)
);
storageAxios.interceptors.response.use(
  response => response,
  error => errorResponseInterceptor(error)
);

export { accountsAxios, supplychainAxios, storageAxios };
