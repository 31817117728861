import React from 'react';
import { Link } from 'react-router-dom';
import { shorten } from 'Utility';
import { BatchInfoPopover } from './BatchPopover';

export const BatchLink = ({ id, name, showPopover = true }) =>
  showPopover ? (
    <BatchInfoPopover batchId={id}>
      <Link to={`/detail/${id}`}>{shorten(name || id)}</Link>
    </BatchInfoPopover>
  ) : (
    <Link to={`/detail/${id}`}>{name || id}</Link>
  );
