import React from 'react';
import { BatchHierarchy } from './BatchHierarchy';
import { BatchHierachyContainer } from './BatchHierachyContainer';

export const BatchPublic = ({
  match: {
    params: { id },
  },
}) => {
  return (
    <BatchHierachyContainer getActs={false} id={id}>
      <BatchHierarchy />
    </BatchHierachyContainer>
  );
};
