import React from 'react';
import { Container } from 'Components';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';

export const Landing = ({ history }) => {
  const { t } = useTranslation();
  const getBatchLocation = batchId => ({
    pathname: `/consumer/${batchId}`,
  });
  const searchBatch = term => history.push(getBatchLocation(term));

  return (
    <Container>
      <h1>{t('landingPage.search batch')}</h1>
      <Input.Search onSearch={searchBatch}></Input.Search>
    </Container>
  );
};
