import React, { useContext, useEffect, useState } from 'react';
import { ContextStore } from 'Components/Store/Store';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { humanizeKey, shorten } from 'Utility';
import { Bold } from 'Components/Markup';
import { getEntry } from 'Pages/Beheer/Adapter';

export const AssetData = ({ labelCol = 8, assetId }) => {
  const {
    state: { assets },
  } = useContext(ContextStore);
  const asset = assets.find(x => x.id === assetId);
  return <TraverseEntries labelCol={labelCol} data={asset.data} />;
};

const PrintEntries = ({ objectKey, value }) => {
  const isString = typeof value === 'string';
  const valueArray = (isString && value.split('_')) || '';
  const [val, setVal] = useState(value);
  const { t } = useTranslation();

  useEffect(() => {
    if (valueArray.length > 1)
      getEntry(valueArray[1], value).then(r => setVal(r.data.data.identifier));
  }, []);

  if (Array.isArray(val)) {
    return (
      <Row>
        {val.map((item, index) => (
          <>
            <Col span={24}>
              <PrintEntries value={item} />
            </Col>
          </>
        ))}
      </Row>
    );
  }
  if (!val) return <div />;
  if (typeof val === 'object') {
    return <TraverseEntries data={val} />;
  }
  if (typeof val === 'boolean') return <div>{t(`form.${val}`, humanizeKey(val))}</div>;
  return <div>{shorten(val)}</div>;
};

const TraverseEntries = ({ labelCol, data }) => {
  const entries = Object.entries(data);
  const { t } = useTranslation();

  return entries.map(([key, value]) => (
    <Row key={key} gutter={10}>
      <Col span={labelCol}>
        <Bold>{t([`data.${key}`, `form.${key}`], humanizeKey(key))}</Bold>
      </Col>
      <Col span={24 - labelCol}>
        <PrintEntries objectKey={key} value={value} />
      </Col>
    </Row>
  ));
};
