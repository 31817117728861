export default {
  productionDate: {
    'ui:widget': 'hidden',
  },
  createParcel: {
    output0: {
      gln: {
        'ui:options': {
          precision: 0,
        },
      },
      initialAmount: {
        'ui:widget': 'hidden',
      },
    },
  },
};
