import { useState, useEffect } from 'react';
import { getEntry } from 'Pages/Beheer/Adapter';

export const shorten = (value, first = 10, last = 10) => {
  if (value.toString().length <= 40) return value;

  const pre = value.toString().substr(0, first);
  const app = value.toString().substr(value.length - last, last);
  return `${pre}...${app}`;
};

export const getAccountSettings = () => JSON.parse(sessionStorage.getItem(getCurrentAccount()));
export const setAccountSettings = settings =>
  sessionStorage.setItem(getCurrentAccount(), JSON.stringify(settings));

export const setCurrentAccount = accountId => sessionStorage.setItem('currentAccount', accountId);
export const getCurrentAccount = () => sessionStorage.getItem('currentAccount');
export const getActiveOrganization = () => {
  const settings = getAccountSettings();
  return settings && settings.activeOrganization;
};
export const setActiveOrganization = id => {
  const accountSettings = getAccountSettings();

  setAccountSettings({
    ...accountSettings,
    activeOrganization: id,
  });
};

export const CollectionEntry = async ({ entry }) => {
  const valueArray = entry.split('_');
  const response = await getEntry(valueArray[1], entry).then(r => r.data);

  return response.data.identifier;
};

export const humanizeKey = key => {
  const humanReadable = key
    .replaceAll('_', ' ') // For_snake_case_keys
    .replace(/([A-Z])/g, ' $1') // ForCamelCaseKeys
    .trim();

  return humanReadable.charAt(0).toUpperCase() + humanReadable.slice(1).toLowerCase();
};
