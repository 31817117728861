import React from 'react';
import { Radio } from 'antd';
import { AntFormItem } from './AntFormItem';

export const AntRadioGroup = props => {
  const { options, value, readonly } = props;
  const RadioItems = options.enumOptions.map(x => (
    <Radio.Button key={x.value} value={x.value}>
      {x.label}
    </Radio.Button>
  ));
  const RadioGroup = (
    <Radio.Group
      value={value}
      buttonStyle="solid"
      disabled={readonly}
      onChange={event => props.onChange(event.target.value)}
    >
      {RadioItems}
    </Radio.Group>
  );

  return <AntFormItem {...props}>{RadioGroup}</AntFormItem>;
};
