import React, { useContext } from 'react';
import { organizationResource } from 'Resources';
import { shorten } from 'Utility';
import { ContextStore } from 'Components';

export const Organization = ({ id }) => {
  const {
    state: { organizations },
  } = useContext(ContextStore);
  const organizationProfile = organizations.find(x => x.id === id);

  if (!organizationProfile) return shorten(id, 10, 10);

  return <span>{organizationProfile.data.identifier}</span>;
};
export const OrganizationFunc = id => {
  const {
    state: { organizations },
  } = useContext(ContextStore);
  const organizationProfile = organizations.find(x => x.id === id);

  if (!organizationProfile) return shorten(id, 10, 10);

  return organizationProfile.data.identifier;
};
