export const getAssetConfigById = (assets, id) => assets.find(asset => asset.id === id);

export const listParents = (assets, asset) => {
  if (!asset.parent) return [];
  const parentAsset = getAssetConfigById(assets, asset.parent);
  return [parentAsset].concat(listParents(assets, parentAsset));
};

export const hasParent = (assets, asset, parent) =>
  listParents(assets, asset)
    .map(a => a.id)
    .includes(parent);

export const listChildren = (assets, asset) => {
  const directChilds = assets.filter(a => a.parent === asset.id);
  const intermediaryChildren = directChilds.flatMap(directChild =>
    listChildren(assets, directChild)
  );
  return directChilds.concat(intermediaryChildren);
};

export const hasChild = (assets, asset, childType) =>
  listChildren(assets, asset)
    .map(a => a.id)
    .includes(childType);

export const getAssetConfigsOfType = (assets, type, showParent = false) => {
  const asset = getAssetConfigById(assets, type);
  if (showParent || !asset.abstract) {
    return listChildren(assets, asset).concat(asset);
  }

  return listChildren(assets, asset);
};

export const isAssetConfigOfType = (assets, assetId, type) =>
  assetId === type || hasParent(assets, getAssetConfigById(assets, assetId), type);
