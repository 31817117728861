import React, { useState, useContext, useEffect } from 'react';
import { handleAct, getSchemaForAct } from 'Pages/Overview/Adapter';
import { notification, Modal, Select, Row, Col, Form } from 'antd';
import { useTranslation } from 'react-i18next';

import { getAssetConfigsOfType, GetAssetData, humanizeKey } from 'Utility';
import produce from 'immer';
import { Accent, CustomForm, ContextStore } from 'Components';
import { AssetData } from 'Components/Helpers/AssetData';
import { AssetName } from 'Components/Helpers/AssetName';
import { getAssetConfigs } from 'Resources/Adapter';
import { BatchListingTitle, filterInputs, isActInputValid } from 'Utility/uiSettings';

export const ActModal = ({
  inputBatches = [],
  act,
  visible,
  setVisible,
  onSubmit,
  onSuccess,
  onFailure,
}) => {
  const getInitialInState = () =>
    inputBatches.map((b, index) => (act.inputs[index].type === 'multiple' ? [b.id] : b.id));

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState({});
  const [actSchema, setActSchema] = useState({});
  const [outState, setOutState] = useState([]);
  const [inState, setInState] = useState(getInitialInState());
  const {
    state: { myAssets, assets, myBatches },
    dispatch,
  } = useContext(ContextStore);

  const [assetConfigs, setAssetConfigs] = useState();

  useEffect(() => {
    (async () => setAssetConfigs((await getAssetConfigs())?.data))();
  }, []);

  const submitAddBatch = async ({ formData }) => {
    if (onSubmit) onSubmit();
    setLoading(true);

    if (!isActInputValid(act, myBatches, formData)) {
      setLoading(false);
      return;
    }

    handleAct(act, inState, outState, formData)
      .then(r => {
        notification.success({
          message: t('handleAct.success.title'),
          description: t('handleAct.success.description', { type: act.id }),
        });

        setFormState({});

        r.data.inputs.map(x => dispatch({ type: 'upsertBatch', batch: x }));
        r.data.outputs.map(x => dispatch({ type: 'upsertBatch', batch: x }));

        if (onSuccess) onSuccess(r.data);

        clearState();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const clearState = () => {
    setVisible(false);
    setFormState({});
    setInState(getInitialInState());
    setOutState([]);
    setActSchema({});
  };

  useEffect(() => {
    if (visible) {
      setLoading(true);
      if (outState.length === act.outputs.length && inState.length === act.inputs.length)
        getSchemaForAct(act, inState, outState)
          .then(r => {
            setActSchema(r.data);
          })
          .finally(() => setLoading(false));
    }
  }, [outState, inState, visible]);

  const layout = {
    modalWidth: act.outputs.length ? 1020 : 500,
    colSpan: act.outputs.length ? { form: 9, productinfo: 15 } : { form: 24, productinfo: 24 },
  };

  return (
    <Modal width={layout.modalWidth} visible={visible} onCancel={clearState} footer={false}>
      <Row gutter={20}>
        <Col span={layout.colSpan.form}>
          <Accent type="h1">{t(`${act.id}.title`, humanizeKey(act.id))}</Accent>
          <p>
            {t(`${act.id}.description`, '', {
              count: act.outputs.length,
              amount: !!inputBatches.length && inputBatches[0].amount,
              assetUnit: !!inputBatches.length && GetAssetData(assets, inputBatches[0].assetId),
            })}
          </p>
          {act.inputs.map((input, index) => {
            const type = input.assetConfigId;
            if (
              index < inputBatches.length &&
              (input.type === 'single' || (act.inputs.length > 1 && index === 0))
            ) {
              return;
            }

            const handleInChangeState = e => {
              const newState = produce(inState, draft => {
                draft[index] = e;
              });
              setInState(newState);
            };

            const inputBatchCandidates = assetConfigs
              ? getAssetConfigsOfType(assetConfigs, type)
                  .flatMap(assetConfig => assets.filter(asset => asset.configId === assetConfig.id))
                  .flatMap(asset => myBatches.filter(b => b.assetId === asset.id))
              : [];

            return (
              <div key={`input-${type}`} style={{ marginBottom: '.5rem' }}>
                <Accent type="h3">{t('pick.input', { type: t(`asset.${type}`, type) })}</Accent>
                <Form.Item
                  validateStatus={
                    input.minimum && inState[index] && input.minimum > inState[index].length
                      ? 'error'
                      : 'success'
                  }
                  help={input.minimum && `Selecteer minimaal ${input.minimum} inputs`}
                >
                  <Select
                    key={`input-form-${type}`}
                    value={inState[index]}
                    style={{ width: '100%' }}
                    placeholder={t('pick.input', { type: t(`asset.${type}`, type) })}
                    mode={input.type === 'array' ? 'multiple' : 'default'}
                    onChange={handleInChangeState}
                  >
                    {assetConfigs &&
                      filterInputs(act, inputBatchCandidates, index).map(x => {
                        return (
                          <Select.Option key={x.id} disabled={inState.includes(x.id)}>
                            <BatchListingTitle batch={x} assets={assets} />
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </div>
            );
          })}
          {act.outputs.map((type, index) => {
            const handleOutChangeState = e => {
              const newState = produce(outState, draft => {
                draft[index] = e;
              });
              setOutState(newState);
            };

            const outputAssetCandidates = assetConfigs
              ? getAssetConfigsOfType(assetConfigs, type).flatMap(assetConfig =>
                  myAssets
                    .concat(assets.filter(asset => !asset.private))
                    .filter(asset => asset.configId === assetConfig.id)
                )
              : [];

            if (!outState[index] && outputAssetCandidates.length === 1) {
              handleOutChangeState(outputAssetCandidates[0].id);
            }

            return (
              <div key={`output${type}`} style={{ marginBottom: '.5rem' }}>
                <Accent type="h3">{t('pick.outcome', { type: t(`asset.${type}`, type) })}</Accent>
                <Select
                  key={`output-form-${type}`}
                  value={outState[index]}
                  style={{ width: '100%' }}
                  placeholder={t('pick.outcome', { type: t(`asset.${type}`, type) })}
                  onChange={handleOutChangeState}
                >
                  {outputAssetCandidates.map(x => (
                    <Select.Option key={x.id}>{x.data.identifier}</Select.Option>
                  ))}
                </Select>
              </div>
            );
          })}
          {Object.entries(actSchema).length > 0 && (
            <CustomForm
              loading={loading}
              config={act}
              jsonschema={actSchema}
              onSubmit={submitAddBatch}
              onCancel={clearState}
              formState={formState}
              setFormState={setFormState}
            />
          )}
        </Col>
        {!!act.outputs.length && (
          <Col span={layout.colSpan.productinfo}>
            {Object.entries(actSchema).length > 0 && (
              <>
                <Accent type="h1">{t('title.assetInformation')}</Accent>
                {outState.map(a => (
                  // TODO set key
                  <>
                    <Accent type="h2">
                      <AssetName assetId={a} />
                    </Accent>
                    <AssetData assetId={a} />
                  </>
                ))}
              </>
            )}
          </Col>
        )}
      </Row>
    </Modal>
  );
};
