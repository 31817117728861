import React from 'react';
import { Col, Table, Button } from 'antd';
import { GetAssetData, GetAssetDataComp, shorten } from 'Utility';
import { Link } from 'react-router-dom';
import { AssetName, Organization } from 'Components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export const BatchTable = ({ batches, showOwner = false, showId = true }) => {
  const { t } = useTranslation();

  const tableCols = [
    {
      key: 'title',
      title: t('batch.product'),
      render: (text, record) => <AssetName assetId={record.assetId} />,
    },
    {
      key: 'amount',
      title: t('batch.amount'),
      render: (text, record) => (
        <>
          {record.amount} <GetAssetDataComp batch={record} />
        </>
      ),
    },
    {
      key: 'date',
      title: t('batch.date'),
      render: (text, record) => moment(record.data.date).format('L'),
    },

    {
      key: 'registraties',
      title: t('batch.registrations_plural'),
      render: (text, record) => record.actRegistrations.length,
    },
  ];

  if (showId)
    tableCols.push({
      key: 'batchid',
      title: 'ID',
      dataIndex: 'id',
      render: text => shorten(text),
    });

  if (showOwner) {
    tableCols.push({
      key: 'legalowner',
      title: t('batch.current legal owner'),
      dataIndex: 'legalOwner',
      render: text => <Organization id={text} />,
    });
    tableCols.push({
      key: 'physicalowner',
      title: t('batch.current physical owner'),
      dataIndex: 'physicalOwner',
      render: text => <Organization id={text} />,
    });
  }
  tableCols.push({
    key: 'action',
    align: 'right',
    render: (text, record) => (
      <Link to={`/detail/${record.id}`}>
        <Button shape="round" type="primary">
          {t('more')}
        </Button>
      </Link>
    ),
  });

  return (
    <Col span={24}>
      <Table rowKey={r => r.id} columns={tableCols} dataSource={batches} />
    </Col>
  );
};
