import React, { useContext, useEffect, Suspense, useState } from 'react';
import Keycloak from 'keycloak-js';

import { Switch, Route } from 'react-router-dom';
import {
  Overview,
  Admin,
  DetailWrapper,
  Error404,
  ExternalOverview,
  CollectionOverview,
  CollectionEntry,
  Search,
} from 'Pages';

import { ContextStore, isAuthorizedFun, Navigation } from 'Components';
import { Spin } from 'antd';
import { setupAxiosWithKeycloak } from 'Utility/api';
import { ProtectedRoute } from 'Utility/protected-route';
import {
  getCollections,
  getMyAccount,
  getAssets,
  getMyAssets,
  getAssetConfigs,
  getOrganizations,
  getAllActs,
} from 'Utility/Adapter';
import { getBatches } from './Overview/Adapter';

export class ErrorBoundary extends React.Component {
  render() {
    const { children } = this.props;
    return children;
  }
}

const keycloak = new Keycloak({
  realm: window.AUTH_REALM,
  url: window.AUTH_URL,
  clientId: 'frontend',
});

export const Private = () => {
  const { state, dispatch } = useContext(ContextStore);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initializeApp = async () => {
      await getOrganizations().then(x =>
        dispatch({ type: 'setOrganizations', group: 'organizations', data: x.data })
      );

      await getAllActs().then(x => dispatch({ type: 'setActs', group: 'acts', data: x.data }));
      await getCollections().then(x =>
        dispatch({ type: 'setCollections', group: 'collections', data: x.data })
      );
      await getAssetConfigs().then(x =>
        dispatch({ type: 'setAssetConfigs', group: 'assetConfigs', data: x.data })
      );
      await getAssets().then(x =>
        dispatch({
          type: 'setAssets',
          group: 'assets',
          data: x.data,
        })
      );

      if (window.ACCOUNTS_API) {
        const myAccount = await getMyAccount().then(r => {
          dispatch({ type: 'setMyAccount', account: r.data });
          return r.data;
        });
        if (myAccount && isAuthorizedFun(myAccount, [{ type: 'account', role: 'User' }])) {
          await getBatches().then(x =>
            dispatch({ type: 'setMyBatches', group: 'myBatches', data: x.data })
          );
          await getMyAssets().then(x =>
            dispatch({ type: 'setMyAssets', data: x.data, group: 'myAssets' })
          );
        }
      } else {
        dispatch({ type: 'setMyAccountFromToken', token: keycloak.tokenParsed });

        await getBatches().then(x =>
          dispatch({ type: 'setMyBatches', group: 'myBatches', data: x.data })
        );
        await getMyAssets().then(x =>
          dispatch({ type: 'setMyAssets', data: x.data, group: 'myAssets' })
        );
      }
    };

    keycloak
      .init({ onLoad: 'login-required' })
      .success(authenticated => {
        if (authenticated) {
          setupAxiosWithKeycloak(keycloak).then(() => {
            dispatch({ type: 'login', keycloak, authenticated });
          });
          initializeApp()
            .then(() => setLoading(false))
            .catch(e => console.error(e));
        }
      })
      .error(e => console.error(e));
  }, []);

  if (loading) {
    return <Spin size="large" />;
  }

  if (state.authenticated && !loading) {
    return (
      <>
        <Suspense fallback={<Spin size="large" />}>
          <Navigation />
        </Suspense>
        <Suspense fallback={<Spin size="large" />}>
          <Switch>
            <ProtectedRoute
              path="/admin"
              component={Admin}
              roles={[
                {
                  type: 'organization',
                  role: 'Admin',
                },
                {
                  type: 'account',
                  role: 'Admin',
                },
              ]}
              redirectPath="/"
            />
            <ProtectedRoute path="/detail/:id" component={DetailWrapper} />
            <ProtectedRoute exact path="/collections/:collection" component={CollectionOverview} />
            <ProtectedRoute path="/collections/:collection/:entry" component={CollectionEntry} />
            <ProtectedRoute path="/reports/search" component={Search} />

            <ProtectedRoute
              roles={[
                {
                  type: 'organization',
                  role: 'User',
                },
              ]}
              path="/"
              exact
              component={Overview}
              redirectPath="/admin"
            />

            <Route component={Error404} status={404} />
          </Switch>
        </Suspense>
      </>
    );
  }
  return false;
};
