import React from 'react';
import { Upload } from 'antd';
import { postAttachment } from 'Resources/estorage';
import { handleAct } from 'Resources/Adapter';
import { Accent } from 'Components';
import { useTranslation } from 'react-i18next';

const { Dragger } = Upload;

export const Attachment = ({ batchId, onSuccess }) => {
  const { t } = useTranslation();
  const customRequest = async options => {
    const uploadResponse = await postAttachment(options.file);

    const formattedResponse = {
      act: {
        ...uploadResponse,
        id: uploadResponse.id.toString(),
      },
    };

    handleAct({ id: 'attachment' }, [batchId], [], formattedResponse).then(r => {
      options.onSuccess(r, options.file);
      onSuccess();
    });
  };

  return (
    <>
      <Dragger customRequest={customRequest} showUploadList={{ showRemoveIcon: false }}>
        <Accent type="h1" style={{ marginTop: '4rem' }}>
          {t('attachment.upload.title')}
        </Accent>
        <Accent type="h4" style={{ marginBottom: '4rem' }}>
          {t('attachment.upload.description')}
        </Accent>
      </Dragger>
    </>
  );
};
