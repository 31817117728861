import React, { useState, useEffect } from 'react';
import { getMyEntries } from 'Pages/Beheer/Adapter';
import produce from 'immer';
import { AntRadioGroup } from './AntRadioGroup';

export const CollectionRadio = props => {
  const [entries, setEntries] = useState([]);

  useEffect(() => {
    getMyEntries('plots').then(r =>
      setEntries(
        r.data.map(x => {
          return {
            value: x.id,
            label: x.identifier,
          };
        })
      )
    );
  }, []);

  const newProps = produce(props, draft => {
    draft.options.enumOptions = entries;
  });

  return <AntRadioGroup {...newProps} />;
};
