import React from 'react';
import { Form } from 'antd';

const FormItem = Form.Item;

export const AntFormItem = ({ schema, rawErrors, children }) => (
  <FormItem
    extra={schema?.extra}
    validateStatus={rawErrors && 'error'}
    help={rawErrors && rawErrors[0]}
  >
    {children}
  </FormItem>
);
