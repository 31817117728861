import React from 'react';
import './Accent.scss';

export const Accent = ({ type, children, className = '', style }) => {
  const propString = {
    style,
    className: `accent ${className}`,
  };

  switch (type) {
    case 'h1':
      return <h1 {...propString}>{children}</h1>;

    case 'h2':
      return <h2 {...propString}>{children}</h2>;

    case 'h3':
      return <h3 {...propString}>{children}</h3>;

    case 'h4':
      return <h4 {...propString}>{children}</h4>;

    case 'div':
      return <div {...propString}>{children}</div>;
    default:
      return <span {...propString}>{children}</span>;
  }
};
