import React from 'react';
import moment from 'moment';
import { Badge, Button, Icon, Row, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { AssetName, Organization } from '../../Components';
import { GetAssetDataComp } from '../../Utility';

export const REPORTS_SETTINGS = [
  {
    id: 'product-table',
    table: {
      columns: [
        {
          key: 'title',
          render: (text, record) => <AssetName assetId={record.assetId} />,
        },
        {
          key: 'amount',
          render: (text, record) => (
            <>
              {record.amount} <GetAssetDataComp batch={record} />
            </>
          ),
        },
        {
          key: 'date',
          render: (text, record) => moment(record.data.date).format('L'),
        },

        {
          key: 'registratiese',
          title: 'Registraties',
          render: (text, record) => record.actRegistrations.length,
        },
        {
          key: 'legalowner',
          dataIndex: 'legalOwner',
          render: text => <Organization id={text} />,
        },
        {
          key: 'physicalowner',
          dataIndex: 'physicalOwner',
          render: text => <Organization id={text} />,
        },
        {
          key: 'action',
          dataIndex: 'id',
          align: 'right',
          render: batchId => (
            <Link to={`/detail/${batchId}`}>
              <Button shape="round" type="primary">
                More
              </Button>
            </Link>
          ),
        },
      ],
    },
  },
  {
    id: 'inventory',
    transform: batches => {
      return Object.entries(
        batches.reduce(
          (acc, batch) => ({
            ...acc,
            [batch.assetId]: acc[batch.assetId] || 0 + batch.amount,
          }),
          {}
        )
      ).map(([k, v]) => ({ assetId: k, amount: v }));
    },
    picharts: [
      {
        tooltip: x => x.label,
        mapToData: inventoryEntries =>
          inventoryEntries.map(entry => ({
            id: entry.assetId,
            value: entry.amount,
            label: `${AssetName({
              assetId: entry.assetId,
            })}`,
          })),
      },
    ],
    table: {
      columns: [
        {
          key: 'title',
          // title: t('batch.product'),
          dataIndex: 'assetId',
          render: text => <AssetName assetId={text} />,
        },
        {
          key: 'amount',
          // title: t('batch.amount'),
          dataIndex: 'amount',
          render: (text, record) => (
            <>
              {text} <GetAssetDataComp batch={record} />
            </>
          ),
        },
      ],
    },
  },
];
