import React from 'react';
import { Select } from 'antd';
import { organizationResource } from 'Resources';
import { AntFormItem } from './AntFormItem';

export const Organizations = props => {
  const organizations = organizationResource.read();

  const RadioItems = organizations.map(x => (
    <Select.Option key={x.id}>{x.data.identifier}</Select.Option>
  ));
  const RadioGroup = (
    <Select
      placeholder="Selecteer een ontvanger"
      buttonStyle="solid"
      onChange={event => {
        props.onChange(event);
      }}
    >
      {RadioItems}
    </Select>
  );

  return <AntFormItem {...props}>{RadioGroup}</AntFormItem>;
};
