// eslint-disable-next-line
import { JSONSchema7Definition, JSONSchema7 } from 'json-schema';
import merge from 'deepmerge';
import deref from 'json-schema-deref-sync';
import mergeAllOf from 'json-schema-merge-allof';
import uiSchemas from './uiSchemas';

export const loadJsonSchema = (id, jsonschema) => {
  if (!jsonschema) throw Error(`No jsconschema`);
  const schema = mergeAllOf(jsonschema, {
    resolvers: {
      extra(values) {
        return values[values.length - 1];
      },
      readOnly(values, path, mergeSchemas, options) {
        return values[values.length - 1];
      },
      enum(values, path, mergeSchemas, options) {
        const shortest = values.reduce((p, c) => (p.length >= c.length ? c : p));
        return shortest;
      },
    },
  });

  console.log(id);

  return {
    schema,
    uiSchema: merge(generateUISchema(schema), uiSchemas[id] || {}),
  };
};

/**
 * @param {JSONSchema7} schema
 */
const generateUISchema = schema => {
  if (schema.oneOf) {
    return schema.oneOf.reduce((acc, current) => ({ ...acc, ...generateUISchema(current) }), {});
  }
  if (schema.anyOf) {
    return schema.anyOf.reduce((acc, current) => ({ ...acc, ...generateUISchema(current) }), {});
  }

  switch (schema.type) {
    case 'object': {
      let uiSchema = {};
      if (schema.dependencies) {
        uiSchema = Object.values(schema.dependencies).reduce(
          (acc, current) => ({ ...acc, ...generateUISchema(current) }),
          {}
        );
      }

      Object.entries(schema.properties).forEach(([k, v]) => {
        uiSchema[k] = generateUISchema(v);
      });

      return uiSchema;
    }
    case 'array':
      if (schema.uniqueItems) {
        return { 'ui:widget': 'AntSelect' };
      }

      return {
        items: generateUISchema(schema.items),
      };
    default:
      return { 'ui:widget': defaultInput(schema) };
  }
};

/**
 * @param {JSONSchema7Definition} property
 */
const defaultInput = property => {
  if (property.readOnly) return 'AntReadOnly';

  switch (property.type) {
    case 'string':
      if (property.format) return defaultStringInputForFormat(property.format);
      if (property.enum) return 'AntSelect';
      return 'AntInput';
    case 'object':
      return;
    case 'boolean':
      return 'AntToggle';
    case 'integer':
    case 'number':
      if (property.enum) return 'AntSelect';

      return 'AntInputNumber';
    default:
      console.log('No input for this property');
  }
};

const defaultStringInputForFormat = format => {
  switch (format) {
    case 'qualities':
    case 'plot':
      return 'CollectionRadio';
    case 'organization':
      return 'Organizations';

    case 'dateYear':
      return 'AntYearPicker';
    case 'date':
      return 'AntDatePicker';
    default:
      throw Error('Unsupported string format');
  }
};
/**
 * @param {JSONSchema7Definition} schema
 */
export const mergeSchema = schema => {
  const resolvedSchema = deref(schema);
  const mergedSchema = mergeAllOf(resolvedSchema);

  return mergedSchema;
};
