import React, { useEffect, useState, useContext, useMemo } from 'react';
import { Timeline, Row, Col, Table, Tabs, Empty, Descriptions, Card, Tag, Icon } from 'antd';
import { Bold, BatchCard, AssetName, Accent, OrganizationFunc, ContextStore } from 'Components';
import { useTranslation } from 'react-i18next';
import { GetAssetData, humanizeKey } from 'Utility';
import moment from 'moment';
import { getEntry } from 'Pages/Beheer/Adapter';
import { groupBy } from 'Utility/group-by';
import { isActHiddenFromTimeline } from 'Utility/uiSettings';
import { BatchContainer } from './BatchContainer';

const { TabPane } = Tabs;

// TODO improve timeline items for each registration type
export const Registrations = ({ batch, pending }) => {
  const { t } = useTranslation();
  const registrations = batch.actRegistrations.filter(
    actReg => !isActHiddenFromTimeline(actReg.type)
  );
  return (
    <>
      <Accent type="h2">{t('registrations.title')}</Accent>
      {!registrations.length ? (
        <p>{t('registrations.empty')}</p>
      ) : (
        <>
          <Timeline pending={pending ? 'Recording...' : undefined} reverse="true">
            {registrations.map(registration => {
              switch (registration.type) {
                default:
                  return (
                    <DefaultItem key={registration.id} batch={batch} registration={registration} />
                  );
              }
            })}
          </Timeline>
        </>
      )}
    </>
  );
};

const DefaultItem = ({ batch, registration }) => {
  const { t } = useTranslation();
  const [location, setLocation] = useState();
  useEffect(() => {
    getEntry('locations', registration.data.newLocation).then(r => setLocation(r.data));
  }, []);

  const {
    state: { assets },
  } = useContext(ContextStore);

  const translationContextData = {
    actor: registration.actor,
    unit: batch.assetId && GetAssetData(assets, batch.assetId),
    batchIdentifier: batch.data.identifier || batch.id,
    asset: batch.assetId && AssetName({ assetId: batch.assetId }),
    createdAt: moment(registration.createdAt).format('L'),
    createdCount: registration.created.length,
    assignedActs:
      registration.data.actsToAssign &&
      registration.data.actsToAssign.map(x => t(`${x}.title`)).join(),
    data: registration.data,
    receiver: registration.data.newOwner && OrganizationFunc(registration.data.newOwner),
    location: location?.data?.identifier,
  };

  return (
    <Timeline.Item key={registration.id}>
      <p>
        {t(
          `registrations.${registration.type}`,
          humanizeKey(registration.type),
          translationContextData
        )}

        <br />
        <small>
          {moment(registration.data.datum).format('L') ||
            moment(registration.createdAt).format('L')}
        </small>
      </p>
      {registration.created.length > 0 && (
        <>
          <Row>
            <Col span={24}>
              <Bold>{t('registrations.created', { count: registration.created.length })}</Bold>
            </Col>
          </Row>
          <Row gutter={20}>
            {registration.created.map(b => (
              <BatchContainer key={b.batchId} id={b.batchId}>
                <BatchCard colSpan={12} />
              </BatchContainer>
            ))}
          </Row>
        </>
      )}
      {registration.modifications[batch.id] && (
        <Row gutter={20}>
          <Descriptions size="small" layout="vertical">
            {registration.modifications[batch.id]
              .filter(m => !m.path.startsWith('/childrenIds'))
              .map(modification => {
                const lastSegmentIndex = modification.path.lastIndexOf('/') + 1;
                const modifiedField = modification.path.substr(lastSegmentIndex);
                const fieldPath = modification.path.substr(0, lastSegmentIndex);
                const fieldPathTranslation = t(
                  `modifications.${fieldPath}`,
                  '',
                  translationContextData
                );

                const renderValue = value =>
                  typeof value === 'number' && value.toString().length === 13
                    ? moment(value).format('L')
                    : value;

                return (
                  <Descriptions.Item
                    label={
                      <span style={{ fontWeight: 425, fontSize: 14 }}>
                        {t(
                          [`modifications.${modifiedField}`, `modifications.${modification.path}`],
                          modifiedField.charAt(0).toUpperCase() + modifiedField.slice(1),
                          translationContextData
                        )}
                        {fieldPathTranslation && ` ${fieldPathTranslation}`}
                      </span>
                    }
                  >
                    <Tag color="orange">{renderValue(modification.oldValue)}</Tag>{' '}
                    <Icon type="arrow-right" />{' '}
                    <Tag color="green" style={{ marginLeft: 8 }}>
                      {renderValue(modification.value)}
                    </Tag>
                  </Descriptions.Item>
                );
              })}
          </Descriptions>
        </Row>
      )}
    </Timeline.Item>
  );
};

export const RegistrationTable = ({ batch, registrations }) => {
  const { t } = useTranslation();

  const groupedRegistrations = useMemo(() => Object.entries(groupBy(registrations, 'type')), [
    registrations,
  ]);

  if (registrations.length === 0) {
    return <Empty description={t('registrations.empty')} />;
  }

  const defaultColumns = [
    {
      title: 'Registratiedatum',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: value => <span>{moment(value).format('YYYY-MM-DD')}</span>,
    },
  ];

  const getColumns = regs => {
    if (!regs.length) return [];
    return Object.entries(regs[0].data)
      .filter(([key, value]) => ['string', 'number'].includes(typeof value) && key !== 'type')
      .map(([key]) => ({
        title: t(`form.${key}`, humanizeKey(key)),
        dataIndex: ['data', key],
        key,
      }));
  };

  return (
    <Tabs defaultActiveKey={groupedRegistrations[0][0]}>
      {groupedRegistrations.map(([key, tabRegistrations]) => (
        <TabPane tab={t(`registrations.${key}`, humanizeKey(key))} key={key}>
          <Table
            dataSource={tabRegistrations.reverse()}
            columns={[...getColumns(tabRegistrations), ...defaultColumns]}
            scroll={{ x: true }}
          />
        </TabPane>
      ))}
    </Tabs>
  );
};
