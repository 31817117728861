import React, { useState } from 'react';
import { Popover, Skeleton, Row, Col } from 'antd';
import { Bold, Organization, AssetName } from 'Components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getBatch } from './Adapter';
import styles from './BatchPopover.module.scss';

export const BatchInfoPopover = ({ batchId, children }) => {
  const [batch, setBatch] = useState({ asset: '', date: '', previousOwners: [], owner: '' });
  const [batchDate, setBatchDate] = useState('');
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation();

  return (
    <Popover
      onVisibleChange={visible => {
        if (visible && !batch.id) {
          const batchPromise = getBatch(batchId);
          const timeoutPromise = new Promise(resolve => {
            // Timeout so that we always see the skeleton
            setTimeout(() => resolve(), 500);
          });

          Promise.all([batchPromise, timeoutPromise]).then(([r]) => {
            setBatchDate(moment(r.createdAt).format('DD MMMM YYYY'));
            setBatch(r.data);
            setLoading(false);
          });
        }
      }}
      placement="right"
      trigger="hover"
      content={
        <div className={loading ? styles.skeleton : styles.batch}>
          <Skeleton loading={loading} active>
            <Row>
              <Col span={12}>
                <Bold>{t('batch.asset')}</Bold>
              </Col>
              <Col span={12}>
                <AssetName assetId={batch.assetId} />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Bold>{t('batch.date')}</Bold>
              </Col>
              <Col span={12}>{batchDate}</Col>
            </Row>
            <Row>
              <Col span={12}>
                <Bold>{t('batch.created by')}</Bold>
              </Col>
              <Col span={12}>
                <Organization id={batch.previousOwners[0] || batch.legalOwner} />
              </Col>
            </Row>
          </Skeleton>
        </div>
      }
    >
      {children}
    </Popover>
  );
};
