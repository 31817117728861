import React from 'react';
import styles from './Bold.module.scss';

export const Bold = ({ type, children, className = '', style }) => {
  switch (type) {
    case 'div':
      return (
        <div style={style} className={`${styles.bold} ${className}`}>
          {children}
        </div>
      );
    default:
      return (
        <strong style={style} className={`${styles.bold} ${className}`}>
          {children}
        </strong>
      );
  }
};
