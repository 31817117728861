import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row, Select, Table, Button, Tabs } from 'antd';
import { ContextStore, Container, Accent } from 'Components';

import { getActiveOrganization, filterByObject, hasChild, listChildren } from 'Utility';
import produce from 'immer';
import { BatchTable } from 'Pages/Overview/BatchTable';
import { ResponsivePie } from '@nivo/pie';
import { postFilter, getAllActs } from './Adapter';
import { ReportPieChart } from './ReportPieChart';
import { REPORTS_SETTINGS } from './ReportsSettings';
import { ReportTable } from './ReportTable';

const { Option } = Select;
const { TabPane } = Tabs;

export const Search = () => {
  const { state, dispatch } = useContext(ContextStore);
  const [acts, setActs] = useState([]);
  const [filter, setFilter] = useState({
    assetConfigs: [],
    acts: [],
    assets: [],
    actsMatch: 'ANY',
  });

  const { t } = useTranslation();

  useEffect(() => {
    getAllActs().then(r => setActs(r));
  }, []);

  const handleSetFilter = (e, group) => {
    const newFilter = produce(filter, draft => {
      draft[group] = e;
    });

    setFilter(newFilter);
    dispatch({
      group: 'searchQuery',
      data: newFilter,
    });
  };

  const submitSearch = () => {
    const searchQuery = [
      {
        type: 'assetConfigs',
        values: state.searchQuery.assetConfigs || [],
      },
      {
        type: 'assets',
        values: state.searchQuery.assets || [],
      },
      {
        type: 'acts',
        values: state.searchQuery.acts || [],
        match: state.searchQuery.actsMatch,
      },
    ];
    postFilter(searchQuery.filter(x => !!x.values.length)).then(r =>
      dispatch({ group: 'searchResult', data: r })
    );
  };

  return (
    <Container>
      <Row style={{ paddingLeft: 20, paddingRight: 20 }}>
        <Accent type="h1">{t('title.searchBatches')}</Accent>
      </Row>
      <Row style={{ paddingLeft: 20, paddingRight: 20 }}>
        <Col span={12} style={{ marginBottom: '2rem' }}>
          <Select
            key="assetConfigs"
            mode="multiple"
            style={{ width: '100%', marginBottom: '.5rem' }}
            placeholder={t('title.filterByType')}
            onChange={e => handleSetFilter(e, 'assetConfigs')}
            defaultValue={state.searchQuery.assetConfigs}
          >
            {state.assetConfigs
              .filter(config => listChildren(state.assetConfigs, config).length === 0)
              .map(x => (
                <Option key={x.id}>{t(`asset.${x.id}`, x.id)}</Option>
              ))}
          </Select>
          <Select
            key="assets"
            mode="multiple"
            style={{ width: '100%', marginBottom: '.5rem' }}
            placeholder={t('title.filterByProduct')}
            onChange={e => handleSetFilter(e, 'assets')}
            defaultValue={state.searchQuery.assets}
          >
            {state.assets.map(asset => (
              <Option key={asset.id}>{asset.data.identifier}</Option>
            ))}
          </Select>
          <Select
            key="acts"
            mode="multiple"
            style={{ width: '100%', marginBottom: '.5rem' }}
            placeholder={t('title.filterByAct')}
            onChange={e => handleSetFilter(e, 'acts')}
            defaultValue={state.searchQuery.acts}
          >
            {[...new Set(acts.filter(act => act.inputs.length > 0).map(act => act.id))].map(id => (
              <Option key={id}>{t(`${id}.title`)}</Option>
            ))}
          </Select>
          <Button type="primary" onClick={submitSearch}>
            {t('navigation.search')}
          </Button>
        </Col>
        {/* <Col span={12}> */}
        {/*  <SearchPie searchResult={state.searchResult} /> */}
        {/* </Col> */}
        <Col span={24}>
          <Tabs>
            {/* <TabPane key="batches" tab={t('title.batches')}> */}
            {/*  <BatchTable batches={state.searchResult} showOwner showId={false} /> */}
            {/* </TabPane> */}
            {/* <TabPane key="products" tab={t('title.products')}> */}
            {/*  <ProductTable batches={state.searchResult} showOwner showId={false} /> */}
            {/* </TabPane> */}
            {REPORTS_SETTINGS.map(report => (
              <TabPane key={report.id} tab={t(`title.${report.id}`)}>
                {report.picharts &&
                  report.picharts.map(piechart => (
                    <ReportPieChart
                      report={report}
                      piechart={piechart}
                      batches={state.searchResult}
                    />
                  ))}
                {report.table && <ReportTable report={report} batches={state.searchResult} />}
              </TabPane>
            ))}
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
};
