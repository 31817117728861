import React, { Suspense, useEffect, useState, useContext } from 'react';
import useReactRouter from 'use-react-router';
import { Switch, Route } from 'react-router-dom';
import { Spin } from 'antd';
import { BatchPublic } from 'Pages/Hierarchy/BatchPublic';
import { Landing } from 'Pages/Landing';
import { getAssets } from 'Utility/Adapter';
import { ContextStore, Container } from 'Components';
import { getOrganizations } from 'Resources/Adapter';
import { ReactComponent as Logo } from 'Components/Navigation/logo.svg';

export const Consumer = () => {
  const { state, dispatch } = useContext(ContextStore);
  const { match, location } = useReactRouter();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Promise.all([
      getAssets().then(x =>
        dispatch({
          type: 'setAssets',
          group: 'assets',
          data: x.data,
        })
      ),
      getOrganizations().then(x =>
        dispatch({ type: 'setOrganizations', group: 'organizations', data: x.data })
      ),
    ]).finally(() => setLoading(false));
  }, []);

  if (loading) return '';

  return (
    <Suspense fallback={<Spin size="large" />}>
      <Container style={{ padding: '20px 0px' }}>
        <Logo />
      </Container>
      <Switch>
        <Route exact path={`${match.path}/`} component={Landing} />
        <Route path={`${match.path}/:id`} component={BatchPublic} />
      </Switch>
    </Suspense>
  );
};
