import React, { useEffect, useState } from 'react';
import { Container } from 'Components';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import { getBatchHierarchy } from './Adapter';

export const BatchHierachyContainer = ({ id, children, handleShowLoading = true }) => {
  const [batch, setBatch] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hierarchy, setHierarchy] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    getBatchHierarchy(id).then(batches => {
      if (batches) {
        const [head, ...tail] = batches.data;
        if (head) {
          setBatch(head);
          setHierarchy(tail);
        }
      }
      setLoading(false);
    });
  }, [id]);

  if (handleShowLoading && loading) {
    return <Spin size="large" />;
  }

  if (batch || !handleShowLoading) {
    return React.Children.map(children, child =>
      React.cloneElement(child, { ...child.props, batch, setBatch, hierarchy, loading })
    );
  }

  return (
    <Container>
      <p>{t('batch_not_found')}</p>
    </Container>
  );
};
