// import { notification } from 'antd';
import { accountsAxios, supplychainAxios } from 'Utility/api';

// This sets the mock adapter on the default instance
// const mock = new MockAdapter(Axios);

export const listOrganizationRoles = () => accountsAxios.get('/roles/organization');
export const postInvite = formData => accountsAxios.post('/accounts/', formData);

// GET /organizations/{id}
export const getOrganization = id => supplychainAxios.get(`/organizations/${id}`);

// PUT /organizations/{id}
export const updateOrganization = values =>
  supplychainAxios.patch(`/organizations/mine`, {
    ...values,
  });

// GET /collections/{id}
export const getCollection = id => supplychainAxios.get(`/collections/${id}`);

// GET /collections/
export const getCollections = () => supplychainAxios.get('/collections');

// GET /collections/{id}/entries/mine
export const getMyEntries = collectionId =>
  supplychainAxios.get(`/collections/${collectionId}/entries/mine`);

// GET /collections/{id}/entries
export const getEntries = collectionId =>
  supplychainAxios.get(`/collections/${collectionId}/entries`);

// GET /collections/{id}/entries/{id}
export const getEntry = (collectionId, entryId) =>
  supplychainAxios.get(`/collections/${collectionId}/entries/${entryId}`);
export const getEntryData = (collectionId, entryId) =>
  supplychainAxios.get(`/collections/${collectionId}/entries/${entryId}`).then(r => r.data);

export const deleteEntry = (collectionId, entryId) =>
  supplychainAxios.delete(`/collections/${collectionId}/entries/${entryId}`);

export const correctEntry = (collectionId, entryId, formData) =>
  supplychainAxios.put(`/collections/${collectionId}/entries/${entryId}`, { ...formData });

// POST /collections/{id}/entries/
export const addEntry = (collectionId, formData) =>
  supplychainAxios.post(`/collections/${collectionId}/entries`, {
    ...formData,
  });

export const addEntryRegistration = (collectionId, collectionEntryId, formData, configId) =>
  supplychainAxios.post(`/collections/${collectionId}/entries/${collectionEntryId}/registrations`, {
    configId,
    data: formData,
  });

// POST /assets/
export const addAsset = (assetConfigId, formData) =>
  supplychainAxios.post('/assets', {
    configId: assetConfigId,
    data: formData,
  });

// GET /assets/{id}/schema
export const getSchemaForAsset = assetConfigId =>
  supplychainAxios.get(`/assetconfigs/${assetConfigId}/schema`);
