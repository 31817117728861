import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { Row, Col, Tag } from 'antd';
import { Bold, Copy, Organization, Accent, AssetData } from 'Components';
import { useTranslation } from 'react-i18next';
import { humanizeKey, shorten } from 'Utility';
import { QRCodeWrapper } from 'Components/QRCode';
import { getEntry } from 'Pages/Beheer/Adapter';
import { BatchLink } from './BatchLink';

export const BatchInfo = ({ batch }) => {
  const { t } = useTranslation();

  const batchDate = moment(batch.createdAt).format('DD MMMM YYYY');
  return (
    <Row>
      <Col span={24}>
        <Accent type="h3">Batch details</Accent>
        <Row>
          <Col span={8}>
            <Bold>Batch</Bold>
          </Col>
          <Col span={16}>
            {shorten(batch.id)} <Copy copyObject={batch.id} />{' '}
            <QRCodeWrapper value={`${window.QR_URL}/${batch.id}`} />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Bold>{t('batch.date')}</Bold>
          </Col>
          <Col span={16}>{batchDate}</Col>
        </Row>
        <Row>
          <Col span={8}>
            <Bold>Status</Bold>
          </Col>
          <Col span={16}>
            <Tag>{t(`status.${batch.status.type}`, humanizeKey(batch.status.type))}</Tag>
          </Col>
        </Row>
        {!!batch.parentIds.length && (
          <Row>
            <Col span={8}>
              <Bold>{t('batch.parents')}</Bold>
            </Col>
            <Col span={16}>
              {batch.parentIds.map(parent => (
                <Row key={parent}>
                  <BatchLink id={parent} />
                </Row>
              ))}
            </Col>
          </Row>
        )}

        {!!batch.linkedIds.length && (
          <Row>
            <Col span={8}>
              <Bold>{t('batch.linked')}</Bold>
            </Col>
            <Col span={16}>
              {batch.linkedIds.map(linked => (
                <Row key={linked}>
                  <BatchLink id={linked} />
                </Row>
              ))}
            </Col>
          </Row>
        )}
        {!!batch.childrenIds.length && (
          <Row>
            <Col span={8}>
              <Bold>{t('batch.children')}</Bold>
            </Col>
            <Col span={16}>
              {batch.childrenIds.map(child => (
                <Row key={child}>
                  <BatchLink id={child} />
                </Row>
              ))}
            </Col>
          </Row>
        )}
        <Row>
          <Col span={8}>
            <Bold>{t('batch.created by')}</Bold>
          </Col>
          <Col span={16}>
            <Organization id={batch.previousOwners[0] || batch.legalOwner} />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Bold>{t('batch.current legal owner')}</Bold>
          </Col>
          <Col span={16}>
            <Organization id={batch.legalOwner} />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Bold>{t('batch.current physical owner')}</Bold>
          </Col>
          <Col span={16}>
            <Organization id={batch.physicalOwner} />
          </Col>
        </Row>
      </Col>
      <Col span={24} style={{ marginTop: '1rem' }}>
        <Accent type="h3">{t('title.asset')}</Accent>

        <AssetData assetId={batch.assetId} />
      </Col>
      <Col span={24} style={{ marginTop: '1rem' }}>
        <Accent type="h3">Data</Accent>
        <TraverseEntries data={batch.data} />
      </Col>
      {/*
      <Col span={24} style={{ marginTop: '1rem' }}>
        <Accent type="h1">{t('title.addAttachment')}</Accent>
        <Attachment />
      </Col> */}
    </Row>
  );
};

const PrintEntries = ({ objectKey, value }) => {
  const { t } = useTranslation();

  const isString = typeof value === 'string';
  const valueArray = (isString && value.split('_')) || '';
  if (typeof value === 'boolean') {
    value = value ? t('form.true') : t('form.false');
  }
  const [val, setVal] = useState(value);

  useEffect(() => {
    if (valueArray.length > 1)
      getEntry(valueArray[1], value).then(r => setVal(r.data.data.identifier));
    else if (['location', 'processingSite', 'packingSite', 'harvestSite'].includes(objectKey))
      getEntry('locations', value).then(r => setVal(r.data.data.identifier));
    else setVal(value);
  }, [value]);

  if (Array.isArray(val)) {
    return (
      <Row>
        {val.map(item => (
          <>
            <Col span={24}>
              <PrintEntries value={item} />
            </Col>
          </>
        ))}
      </Row>
    );
  }
  if (typeof val === 'object') {
    return <TraverseEntries data={val} />;
  }
  return <div>{shorten(val)}</div>;
};

const TraverseEntries = ({ data }) => {
  const entries = Object.entries(data);
  const { t } = useTranslation();

  return entries.map(([key, value]) => (
    <Row key={key} gutter={10}>
      <Col span={8}>
        <Bold>{t([`data.${key}`, `form.${key}`], humanizeKey(key))}</Bold>
      </Col>
      <Col span={16}>
        <PrintEntries objectKey={key} value={value} />
      </Col>
    </Row>
  ));
};
