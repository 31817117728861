import qs from 'qs';
import { accountsAxios, supplychainAxios } from 'Utility/api';

export const getMyAccount = () => accountsAxios.get('/accounts/mine');
export const getMyAssets = () => supplychainAxios.get('/assets/mine');
export const getAssets = () => supplychainAxios.get('/assets');
export const getOrganizations = () => supplychainAxios.get('/collections/organizations/entries');
export const getAssetConfigs = () => supplychainAxios.get('/assetconfigs');
export const getCollections = () => supplychainAxios.get('/collections');
export const getAllActs = () => supplychainAxios.get('acts/all');
// export const getActsForInputs = inputBatchIds =>
//   supplychainAxios.get('/acts', {
//     params: {
//       inputBatchIds,
//     },
//     paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
//   });
export const getActsForInputs = inputBatchIds =>
  supplychainAxios.post('/acts', {
    inputBatchIds,
  });
