import React from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { AntFormItem } from './AntFormItem';

export const AntSelect = props => {
  const { t } = useTranslation();
  const { options, value, readonly, multiple } = props;
  const RadioItems = options.enumOptions.map(x => (
    <Select.Option key={x.value} value={x.value}>
      {t(x.label)}
      {/* {t([`${x.label}.title`, x.label])} */}
    </Select.Option>
  ));
  const RadioGroup = (
    <Select
      mode={multiple ? 'multiple' : ''}
      value={value}
      buttonStyle="solid"
      disabled={readonly}
      onChange={event => props.onChange(event)}
    >
      {RadioItems}
    </Select>
  );

  return <AntFormItem {...props}>{RadioGroup}</AntFormItem>;
};
