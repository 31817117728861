import React from 'react';
import copy from 'copy-to-clipboard';
import { message, Button } from 'antd';
import { useTranslation } from 'react-i18next';

export const Copy = ({ copyObject, type = 'string' }) => {
  let copiedObject = copyObject;
  const { t } = useTranslation();
  switch (type) {
    case 'object':
      copiedObject = JSON.stringify(copyObject);
      break;
    case 'array':
      copiedObject = copyObject.toString();
      break;
    default:
      break;
  }

  return (
    <Button
      shape="circle"
      icon="copy"
      onClick={() => {
        message.success(t('copied'));
        copy(copiedObject);
      }}
    />
  );
};
