import React from 'react';
import { Input } from 'antd';
import { AntFormItem } from './AntFormItem';

export const AntInput = props => {
  const { value, required, onChange } = props;
  return (
    <AntFormItem {...props}>
      <Input value={value} required={required} onChange={event => onChange(event.target.value)} />
    </AntFormItem>
  );
};
