import React from 'react';
import { Switch } from 'antd';
import { AntFormItem } from './AntFormItem';

export const AntToggle = props => {
  const { value, required, onChange } = props;
  return (
    <AntFormItem {...props}>
      <Switch required={required} defaultChecked={value} onChange={checked => onChange(checked)} />
    </AntFormItem>
  );
};
