import { useContext } from 'react';
import { ContextStore } from 'Components/Store/Store';
import { getActiveOrganization } from 'Utility';

export const IsAuthorized = ({ children, organizationRole }) => {
  const { state } = useContext(ContextStore);

  console.log(state);
  if (!state.myAccount) return false;
  const organization =
    state.myAccount && state.myAccount.organizations.find(x => x.id === getActiveOrganization());

  if (organization.roles.includes(organizationRole)) return children;

  return null;
};

export const isAuthorizedFun = (account, roles) => {
  console.log(account, roles);
  if (!account || !roles) return false;

  return roles.some(({ type, role }) => {
    switch (type) {
      case 'account':
        return account.roles.includes(role);
      case 'organization': {
        const organization = account.organizations.find(x => x.id === getActiveOrganization());
        return organization && organization.roles.includes(role);
      }
      default:
        throw Error(`Unknown type ${type}`);
    }
  });
};
