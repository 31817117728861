import React from 'react';
import { Accent } from 'Components';
import { useTranslation } from 'react-i18next';

export const Landing = () => {
  const { t } = useTranslation();
  return (
    <>
      <Accent type="h1">{t('admin.title')}</Accent>
      <p>{t('admin.description')}</p>
    </>
  );
};
