import React from 'react';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const Error404 = () => {
  const { t } = useTranslation();

  return (
    <Result
      status="404"
      title="404"
      subTitle={t('under construction')}
      extra={
        <Link to="/">
          <Button type="primary">{t('back to overview')}</Button>
        </Link>
      }
    />
  );
};
