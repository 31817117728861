import React, { useState, useEffect, useContext } from 'react';
import {
  Container,
  AssetName,
  Accent,
  OrganizationFunc,
  Bold,
  AssetData,
  Organization,
  ContextStore,
} from 'Components';
import { useTranslation } from 'react-i18next';
import { Row, Col, Timeline } from 'antd';
import { GetAssetData, humanizeKey, shorten } from 'Utility';
import moment from 'moment';
import { getEntry } from 'Pages/Beheer/Adapter';
import TimelineItem from 'antd/lib/timeline/TimelineItem';

export const BatchHierarchy = ({
  batch,
  hierarchy,
  showBatchDetail = true,
  showRegistrations = true,
}) => {
  const { t } = useTranslation();

  if (!batch) return null;

  return (
    <Container>
      <Row gutter={20}>
        {showBatchDetail && (
          <Col span={24}>
            <BatchDetail batch={batch} />
          </Col>
        )}
        <Col span={24}>
          <Accent type="h1">{t('hierarchy.title')}</Accent>
          <ParentBatches
            batch={batch}
            hierarchy={hierarchy}
            showRegistrations={showRegistrations}
          />
        </Col>
      </Row>
    </Container>
  );
};

const hiddenRegs = ['accept', 'deny'];

const ParentBatches = ({ batch, hierarchy, showRegistrations }) => {
  const { t } = useTranslation();

  const {
    state: { assets },
  } = useContext(ContextStore);

  const Parents = hierarchy.map((x, index) => {
    const isRoot = index === hierarchy.length - 1;

    const registrations = x.actRegistrations
      .filter(reg => !hiddenRegs.includes(reg.type))
      .reverse()
      .map(registration => {
        const createdBatch = hierarchy.find(hierarchybatch =>
          registration.created.find(b => b.batchId === hierarchybatch.id)
        );
        return (
          <TimelineItem>
            <h2>
              {t(`registrations.${registration.type}`, humanizeKey(registration.type), {
                amount:
                  registration.data.amountOfVegetable ||
                  registration.data.amountOfFruit ||
                  registration.data.amount,
                unit: x.assetId && GetAssetData(assets, x.assetId),
                identifier: x.identifier || x.id,
                asset: x.assetId && AssetName({ assetId: x.assetId }),
                totalPrice: registration.data.totalPrice || registration.data.price,
                createdAt: moment(registration.createdAt).format('L'),
                date: moment(registration.data.date).format('L'),
                count: registration.created.length,
                assignedActs:
                  registration.data.actsToAssign &&
                  registration.data.actsToAssign.map(reg => t(`${reg}.title`)).join(),
                data: registration.data,
                receiver:
                  registration.data.newOwner && OrganizationFunc(registration.data.newOwner),
              })}
            </h2>

            {createdBatch && <BatchDetail batch={createdBatch} />}
          </TimelineItem>
        );
      });

    return (
      <>
        {showRegistrations && registrations}
        {isRoot && (
          <TimelineItem>
            <BatchDetail batch={x} />
          </TimelineItem>
        )}
      </>
    );
  });

  return Parents;
};

const BatchDetail = ({ batch }) => {
  const { t } = useTranslation();
  const batchDate = moment(batch.createdAt).format('DD MMMM YYYY');

  const {
    state: { assets },
  } = useContext(ContextStore);

  return (
    <Row style={{ marginBottom: 30 }}>
      <h3>
        <Accent>
          {batch.amount} {GetAssetData(assets, batch.assetId)}
        </Accent>{' '}
        <AssetName assetId={batch.assetId} /> {t('batch.from')} {batchDate}
      </h3>
      <Col xs={24} lg={8}>
        <Accent type="h3">Batch details</Accent>
        <Row>
          <Col span={8}>
            <Bold>Batch</Bold>
          </Col>
          <Col span={16}>{shorten(batch.id)}</Col>
        </Row>
        <Row>
          <Col span={8}>
            <Bold>{t('batch.date')}</Bold>
          </Col>
          <Col span={16}>{batchDate}</Col>
        </Row>
        {!!batch.parentIds.length && (
          <Row>
            <Col span={8}>
              <Bold>{t('batch.parents')}</Bold>
            </Col>
            <Col span={16}>
              {batch.parentIds.map(parent => (
                <Row key={parent}>{shorten(parent)}</Row>
              ))}
            </Col>
          </Row>
        )}
        <Row>
          <Col span={8}>
            <Bold>{t('batch.created by')}</Bold>
          </Col>
          <Col span={16}>
            <Organization id={batch.previousOwners[0] || batch.legalOwner} />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Bold>{t('batch.current legal owner')}</Bold>
          </Col>
          <Col span={16}>
            <Organization id={batch.legalOwner} />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Bold>{t('batch.current physical owner')}</Bold>
          </Col>
          <Col span={16}>
            <Organization id={batch.physicalOwner} />
          </Col>
        </Row>
      </Col>
      <Col span={8}>
        <Accent type="h3">{t('title.asset')}</Accent>
        <AssetData labelCol={12} assetId={batch.assetId} />
      </Col>
      <Col span={8}>
        <Accent type="h3">Data</Accent>
        <TraverseEntries data={batch.data} />
      </Col>
    </Row>
  );
};

const PrintEntries = ({ value }) => {
  const isString = typeof value === 'string';
  const valueArray = (isString && value.split('_')) || '';
  const [val, setVal] = useState(value);

  useEffect(() => {
    if (valueArray.length > 1)
      getEntry(valueArray[1], value).then(r => setVal(r.data.data.identifier));
  }, [value]);

  if (Array.isArray(val)) {
    return (
      <Row>
        {val.map((item, index) => (
          <>
            <Col span={24}>
              <PrintEntries value={item} />
            </Col>
          </>
        ))}
      </Row>
    );
  }
  if (typeof val === 'object') {
    return <TraverseEntries data={val} />;
  }
  return <div>{shorten(val)}</div>;
};

const TraverseEntries = ({ data }) => {
  const entries = Object.entries(data);
  const { t } = useTranslation();

  return entries.map(([key, value]) => (
    <Row key={key} gutter={10}>
      <Col span={12}>
        <Bold>{t([`data.${key}`, `form.${key}`], humanizeKey(key))}</Bold>
      </Col>
      <Col span={12}>
        <PrintEntries value={value} />
      </Col>
    </Row>
  ));
};
