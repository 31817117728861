import React from 'react';
import { getActiveOrganization } from 'Utility';
import { BatchCardGrid } from './BatchCardGrid';
import { BatchTable } from './BatchTable';

export const InternalOverview = ({ viewState = 'grid', filteredData }) => {
  return (
    <>
      {viewState === 'grid' && (
        <BatchCardGrid
          batches={filteredData.filter(x => x.physicalOwner === getActiveOrganization())}
        />
      )}
      {viewState === 'table' && (
        <BatchTable
          batches={filteredData.filter(x => x.physicalOwner === getActiveOrganization())}
        />
      )}
    </>
  );
};
