import React from 'react';
import Form from 'react-jsonschema-form';
import { Button } from 'antd';
import { loadJsonSchema } from 'Utility/JsonSchema';
import { Accent } from 'Components';
import { useTranslation } from 'react-i18next';
import { humanizeKey } from 'Utility';
import {
  AntRadioGroup,
  AntSelect,
  AntInput,
  AntInputNumber,
  AntTextArea,
  AntDatePicker,
  AntYearPicker,
  AntArray,
  CollectionRadio,
  Organizations,
  AntReadOnly,
  AntToggle,
} from './Widgets';

const widgets = {
  AntRadioGroup,
  AntInput,
  AntSelect,
  AntInputNumber,
  AntTextArea,
  AntDatePicker,
  AntYearPicker,
  AntArray,
  CollectionRadio,
  Organizations,
  AntReadOnly,
  AntToggle,
};

const CustomFieldTemplate = props => {
  const { t } = useTranslation();

  const { id, classNames, label, help, required, description, children, schema } = props;
  return (
    <div className={classNames}>
      {id !== 'root' && schema.type === 'object' && label && (
        <Accent type="h2" htmlFor={id}>
          {t([`category.${label}`, `asset.${label}`, label])} {required ? '*' : null}
        </Accent>
      )}

      {id !== 'root' && schema.type !== 'object' && label && (
        <label htmlFor={id}>
          {schema.title
            ? schema.title
            : label && t(`form.${label.toString()}`, humanizeKey(label.toString()))}
          {required ? '*' : null}
        </label>
      )}
      {id !== 'root' && description}
      {children}
      {help}
    </div>
  );
};
const ObjectFieldTemplate = ({ description, properties }) => (
  <>
    {description}
    {properties.map(({ content }) => content)}
  </>
);

export const CustomForm = ({
  jsonschema,
  config,
  loading,
  onSubmit,
  onCancel,
  formState,
  setFormState,
}) => {
  const { schema, uiSchema } = loadJsonSchema(config, jsonschema);
  const { t } = useTranslation();
  return (
    <Form
      schema={schema}
      uiSchema={uiSchema}
      widgets={widgets}
      showErrorList={false}
      FieldTemplate={CustomFieldTemplate}
      ObjectFieldTemplate={ObjectFieldTemplate}
      ArrayFieldTemplate={AntArray}
      noHtml5Validate
      onSubmit={onSubmit}
      formData={formState}
      onChange={({ formData }) => setFormState(formData)}
    >
      <>
        <Button loading={loading} type="primary" shape="round" htmlType="submit">
          {t('form.submit')}
        </Button>
        <Button disabled={loading} onClick={onCancel} type="link">
          {t('form.cancel')}
        </Button>
      </>
    </Form>
  );
};
