import React from 'react';
import { Input } from 'antd';
import { AntFormItem } from './AntFormItem';

export const AntTextArea = props => {
  const { value, required, onChange } = props;
  return (
    <AntFormItem {...props}>
      <Input.TextArea
        value={value}
        required={required}
        onChange={event => onChange(event.target.value)}
      />
    </AntFormItem>
  );
};
