import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { ContextStore } from 'Components';

import { getBatchForwardHierarchy } from './Adapter';

import { BatchTable } from '../Overview/BatchTable';

export const BatchTracing = ({ batch }) => {
  const { t } = useTranslation();
  const [batches, setBatches] = useState([batch]);
  const { state } = useContext(ContextStore);
  const [assetFilter, setAssetFilter] = useState([]);
  const [actFilter, setActFilter] = useState([]);

  useEffect(() => {
    getBatchForwardHierarchy(batch.id).then(r => setBatches(r.data));
  }, [batch]);

  const filteredBatches = batches
    .filter(b => assetFilter.length === 0 || assetFilter.includes(b.assetId))
    .filter(
      b => actFilter.length === 0 || b.actRegistrations.some(reg => actFilter.includes(reg.type))
    );

  return (
    <>
      <Row style={{ paddingLeft: 20, paddingRight: 20 }}>
        <Col lg={4} xs={24} style={{ marginBottom: '1rem' }}>
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder={t('trace.filterByType')}
            onChange={e => setAssetFilter(e)}
          >
            {batches
              .map(b =>
                state.myAssets.length && state.myAssets.find(a => a.id === b.assetId)
                  ? state.myAssets.find(a => a.id === b.assetId)
                  : false
              )
              .filter((value, index, self) => self.indexOf(value) === index)
              .map(
                asset =>
                  asset && <Select.Option key={asset.id}>{asset.data.identifier}</Select.Option>
              )}
          </Select>
        </Col>
        <Col lg={{ offset: 1, span: 4 }} xs={24} style={{ marginBottom: '1rem' }}>
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder={t('trace.filterByAct')}
            optionLabelProp="label"
            onChange={e => setActFilter(e)}
          >
            {[...new Set(batches.flatMap(b => b.actRegistrations.map(r => r.type)))].map(act => (
              <Select.Option key={act} label={t(`${act}.title`)}>
                {t(`${act}.title`)}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row>
        <BatchTable batches={filteredBatches} />
      </Row>
    </>
  );
};
