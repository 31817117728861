import * as React from 'react';
import produce from 'immer';
import { setAccountSettings, getAccountSettings, setCurrentAccount } from 'Utility';

const ContextStore = React.createContext();

const initialState = {
  language: 'nl',
  keycloak: false,
  authenticated: false,
  batches: [],
  myAccount: null,
  myAssets: [],
  assets: [],
  myBatches: [],
  organizations: [],
  acts: [],
  activeOrganization: {},
  searchQuery: [],
  searchResult: [],
};

const reducer = (state, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case 'setBatches': {
        draft.batches = action.batches.sort((a, b) => {
          a = new Date(a.createdAt);
          b = new Date(b.createdAt);
          if (a === b) return 0;
          return a < b ? 1 : -1;
        });
        break;
      }

      case 'upsertBatch': {
        const batch = draft.batches.find(b => b.id === action.batch.id);
        if (batch) {
          const index = draft.batches.indexOf(batch);
          draft.batches[index] = action.batch;
        } else {
          draft.batches.unshift(action.batch);
        }
        const myBatch = draft.myBatches.find(b => b.id === action.batch.id);
        if (myBatch) {
          const index = draft.myBatches.indexOf(myBatch);
          draft.myBatches[index] = action.batch;
        } else {
          draft.myBatches.unshift(action.batch);
        }
        break;
      }

      case 'addBatch':
        draft.batches.unshift(action.batch);
        break;

      case 'login':
        draft.keycloak = action.keycloak;
        draft.authenticated = action.authenticated;
        draft.user = {
          firstName: action.keycloak.idTokenParsed.given_name,
          lastName: action.keycloak.idTokenParsed.family_name,
          name: action.keycloak.idTokenParsed.name,
          username: action.keycloak.idTokenParsed.preferred_username,
          id: action.keycloak.idTokenParsed.sub,
        };
        break;
      case 'notAuthenticated':
        draft.keycloak = action.keycloak;
        draft.authenticated = false;
        draft.user = null;
        break;
      case 'setMyAccount': {
        draft.myAccount = action.account;
        const accountSettings = getAccountSettings();

        setCurrentAccount(action.account.id);

        if (action.account.roles.includes('User'))
          setAccountSettings({
            ...accountSettings,
            activeOrganization: action.account.organizations[0].id,
          });

        break;
      }
      case 'setMyAccountFromToken': {
        const orgs = action.token.organizations.sort();
        const firstOrg = orgs[0];
        const myAccount = {
          id: action.token.sub,
          organizations: orgs.map(x => ({
            id: x,
            roles: action.token.roles,
          })),
          roles: action.token.roles,
        };

        draft.myAccount = myAccount;

        const accountSettings = getAccountSettings();

        setCurrentAccount(action.token.sub);

        if (
          action.token.roles.includes('User') &&
          (!accountSettings || !accountSettings.activeOrganization)
        )
          setAccountSettings({
            ...accountSettings,
            activeOrganization: firstOrg,
          });

        break;
      }
      case 'addAsset':
        draft.myAssets.unshift(action.asset);
        draft.assets.unshift(action.asset);
        break;
      case 'activeOrganization':
        draft.activeOrganization = action.organization;
        break;
      case 'reset':
        return initialState;
      case 'changeLanguage':
        draft.language = action.language;
        break;
      default:
        draft[action.group] = action.data;
        break;
    }
  });
};

const ContextStoreProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const value = { state, dispatch };

  return <ContextStore.Provider value={value}>{children}</ContextStore.Provider>;
};

const ContextStoreConsumer = ContextStore.Consumer;

export { ContextStore, ContextStoreProvider, ContextStoreConsumer };
