import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/en_US';
import { AntFormItem } from './AntFormItem';

export const AntDatePicker = props => {
  const { value, onChange, required } = props;
  return (
    <AntFormItem {...props}>
      <DatePicker
        locale={locale}
        required={required}
        defaultValue={value && moment(value)}
        onChange={(date, dateString) => onChange(dateString)}
      />
    </AntFormItem>
  );
};

export const AntYearPicker = props => {
  const { value, onChange } = props;
  return (
    <AntFormItem {...props}>
      <DatePicker
        mode="year"
        locale={locale}
        defaultValue={value && moment(value)}
        onChange={(date, dateString) => onChange(dateString)}
      />
    </AntFormItem>
  );
};
