import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'antd';
import QRCode from 'qrcode';
import jsPDF from 'jspdf';

const downloadQRCode = value => {
  const opts = {
    errorCorrectionLevel: 'H',
    type: 'image/jpeg',
    width: 800,
    rendererOpts: {
      quality: 0.4,
    },
  };
  QRCode.toDataURL(value, opts, (err, url) => {
    if (err) throw err;

    // eslint-disable-next-line new-cap
    const pdf = new jsPDF();
    pdf.addImage(url, 'JPEG', 0, 0);
    pdf.save('qrcode.pdf');
  });
};

export const QRCodeWrapper = ({ value }) => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const hideModal = () => setShowModal(false);

  return (
    <>
      <Button
        shape="circle"
        icon="qrcode"
        onClick={() => {
          downloadQRCode(value);
        }}
      />
    </>
  );
};
