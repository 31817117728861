import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { CollectionSchemaModal } from 'Components';
import { humanizeKey } from 'Utility';

export const SchemaButton = ({
  config,
  onSubmit,
  loading,
  formState,
  setFormState,
  visible,
  setVisible,
}) => {
  const { t } = useTranslation();

  const onClick = () => {
    setVisible(true);
  };

  return (
    <>
      <Button type="primary" shape="round" onClick={onClick}>
        {t(`add.entry`, { item: t(`entities.${config.id}`, humanizeKey(config.id)) })}
      </Button>
      <CollectionSchemaModal
        loading={loading}
        config={config}
        visible={visible}
        setVisible={setVisible}
        onSubmit={onSubmit}
        formState={formState}
        setFormState={setFormState}
      />
    </>
  );
};
