import { supplychainAxios } from 'Utility/api';
import qs from 'qs';

// POST /supplychain/
// body: {id, inputBatchIds, outputAssetIds, data}
export const handleAct = (act, batchIds = [], outState, formData) =>
  supplychainAxios.post('/supplychain/', {
    id: act.id,
    inputBatchIds: batchIds,
    outputAssetIds: outState,
    data: formData,
  });

// GET /batches/mine
export const getBatches = () => supplychainAxios.get(`/batches/mine`);

export const getSchemaForAct = (act, inputBatchIds, outputs) =>
  supplychainAxios.post(`/acts/${act.id}/schema`, {
    inputBatchIds,
    outputAssetIds: outputs,
  });

export const getAllBatches = () => supplychainAxios.post(`/batches/filter`, []).then(r => r.data);
