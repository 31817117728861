import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { ContextStoreProvider } from 'Components/Store/Store';
import { Consumer, Private } from 'Pages';
import * as serviceWorker from './serviceWorker';

import './i18n';
import './index.scss';

const WrappedApp = () => {
  return (
    <ContextStoreProvider>
      <Router>
        <Switch>
          <Route path="/consumer" component={Consumer} />
          <Route path="/" component={Private} />
        </Switch>
      </Router>
    </ContextStoreProvider>
  );
};

ReactDOM.render(<WrappedApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
