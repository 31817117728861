import React, { useState } from 'react';
import useReactRouter from 'use-react-router';

import { useTranslation } from 'react-i18next';
import { Button, notification } from 'antd';
import { RegistrationModal } from 'Components/RegistrationModal/RegistrationModal';
import { addEntryRegistration } from 'Pages/Beheer/Adapter';

export const RegistrationButton = ({ config, loading, formState, setFormState, setLoading }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  const {
    match: { params },
  } = useReactRouter();

  // TODO get from router
  const collectionId = params.collection;
  const entryId = params.entry;

  const onSubmit = (configId, formData) => {
    setLoading(true);
    addEntryRegistration(collectionId, entryId, formData, configId)
      .then(r => {
        notification.success({
          message: t('handleAct.success.title'),
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Button type="primary" shape="round" onClick={() => setVisible(true)}>
        {t(`add.${config.id}`)}
      </Button>
      <RegistrationModal
        loading={loading}
        config={config}
        visible={visible}
        setVisible={setVisible}
        onSubmit={onSubmit}
        formState={formState}
        setFormState={setFormState}
      />
    </>
  );
};
