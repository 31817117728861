import { unstable_createResource as createResource } from 'react-cache';

import { getCollections, getOrganizations } from './Adapter';

export const collectionResource = createResource(() => {
  return new Promise(resolve => {
    getCollections().then(r => resolve(r.data));
  });
});

export const organizationResource = createResource(() => {
  return new Promise(resolve => {
    getOrganizations().then(r => resolve(r.data));
  });
});
