import React, { useEffect, useState } from 'react';
import useReactRouter from 'use-react-router';

import { useTranslation } from 'react-i18next';
import { Accent, Container } from 'Components';
import { Spin, Row, Col, notification, Table, Tabs } from 'antd';

import { collectionResource } from 'Resources';
import { RegistrationButton } from 'Components/RegistrationButton/RegistrationButton';
import { humanizeKey, shorten } from 'Utility';
import moment from 'moment';
import { isObject } from 'util';
import { getEntry } from '../Beheer/Adapter';

const { TabPane } = Tabs;

export const CollectionEntry = () => {
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState({});

  const { t } = useTranslation();
  const collectionResources = collectionResource.read();

  const {
    match: { params },
  } = useReactRouter();

  const [entry, setEntry] = useState();

  // TODO get from router
  const collectionId = params.collection;
  const entryId = params.entry;

  useEffect(() => {
    getEntry(collectionId, entryId).then(r => setEntry(r.data));
  }, [params.collection, loading]);

  if (!entry) {
    return <Spin />;
  }

  const selectedCollection = collectionResources.find(c => c.id === entry.collectionId);

  return (
    <Container>
      <Row>
        <Accent type="h1">{entry.data.identifier}</Accent>
      </Row>
      <Row>
        <TraverseEntries data={entry.data} />
      </Row>
      <Row gutter={10} type="flex" style={{ marginTop: '2rem', marginBottom: '2rem' }}>
        {selectedCollection.registrationConfigs &&
          selectedCollection.registrationConfigs.map(act => (
            <Col key={act.id}>
              <RegistrationButton
                loading={loading}
                config={act}
                formState={formState}
                setFormState={setFormState}
                setLoading={setLoading}
              />
            </Col>
          ))}
      </Row>
      <Row>
        <Col span={24}>
          {selectedCollection.registrationConfigs && (
            <Tabs defaultActiveKey={selectedCollection.registrationConfigs[0].id}>
              {selectedCollection.registrationConfigs.map(regConfig => (
                <TabPane tab={t(`title.${regConfig.id}`)} key={regConfig.id}>
                  <RegistrationsTable
                    key={regConfig.id}
                    entries={entry.registrations.filter(reg => reg.configId === regConfig.id)}
                  />
                </TabPane>
              ))}
            </Tabs>
          )}
        </Col>
      </Row>
    </Container>
  );
};

const RegistrationsTable = ({ entries }) => {
  const { t } = useTranslation();
  const regColumns = [
    {
      key: 'id',
      title: 'ID',
      dataIndex: 'id',
      render: text => shorten(text),
    },
    ...Object.entries(entries.length === 0 ? {} : entries[0].data)
      .filter(([k, v]) => !isObject(v))
      .map(([k, v]) => ({
        key: k,
        title: t(`form.${k}`, humanizeKey(k)),
        dataIndex: `data.${k}`,
      })),
  ];

  return <Table dataSource={entries} columns={regColumns} />;
};

const PrintEntries = ({ value }) => {
  if (Array.isArray(value)) {
    return (
      <Row>
        {value.map((item, index) => (
          <>
            <Col span={1}>{index}.</Col>
            <Col span={3}>
              <PrintEntries value={item} />
            </Col>
          </>
        ))}
      </Row>
    );
  }
  if (typeof value === 'object') {
    return (
      <Row>
        <Col offset={1}>
          <TraverseEntries data={value} />
        </Col>
      </Row>
    );
  }
  return <div>{value}</div>;
};

const TraverseEntries = ({ data }) => {
  const entries = Object.entries(data);
  const { t } = useTranslation();

  return entries.map(([key, value]) => {
    return (
      <Row key={key}>
        <strong>{t(`form.${key}`, humanizeKey(key))}</strong>
        <PrintEntries value={value} />
      </Row>
    );
  });
};
