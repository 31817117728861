import React, { useContext } from 'react';
import useReactRouter from 'use-react-router';

import { Link } from 'react-router-dom';
import { Row, Col, Menu, Icon, Button } from 'antd';
import { Container, ContextStore, isAuthorizedFun } from 'Components';
import { useTranslation } from 'react-i18next';

import { organizationResource } from 'Resources';

import { getActiveOrganization, humanizeKey, setActiveOrganization } from 'Utility';
import { ReactComponent as Logo } from './logo.svg';

import styles from './Navigation.module.scss';

const { SubMenu } = Menu;

export const Navigation = () => {
  const { state } = useContext(ContextStore);
  const { t } = useTranslation();
  const { location } = useReactRouter();

  const selectedKeys = location.pathname.split('/');

  const activeOrg = getActiveOrganization();

  return (
    <Container className={styles.nav}>
      <Row type="flex" align="middle" style={{ paddingLeft: 20, paddingRight: 20 }}>
        <Col span={6} className={styles.navLogo}>
          <Link to="/">
            <Logo />
          </Link>
        </Col>
        <Col span={18} style={{ textAlign: 'right' }}>
          {state.authenticated ? (
            <Menu
              mode="horizontal"
              className={styles.navMenu}
              selectedKeys={[selectedKeys[selectedKeys.length - 1]]}
            >
              {isAuthorizedFun(state.myAccount, [{ type: 'organization', role: 'User' }]) && (
                <Menu.Item key="overview">
                  <Link to="/">{t('navigation.overview')}</Link>
                </Menu.Item>
              )}
              <Menu.Item key="search">
                <Link to="/reports/search">{t('navigation.search')}</Link>
              </Menu.Item>
              {isAuthorizedFun(state.myAccount, [{ type: 'organization', role: 'User' }]) &&
                state.collections &&
                !!state.collections.length &&
                state.collections
                  .filter(c => !!c.registrationConfigs.length)
                  .map(c => (
                    <Menu.Item key={c.id}>
                      <Link to={`/collections/${c.id}`}>
                        {t(`entities.${c.id}_plural`, humanizeKey(c.id))}
                      </Link>
                    </Menu.Item>
                  ))}

              {isAuthorizedFun(state.myAccount, [
                { type: 'organization', role: 'Admin' },
                { type: 'account', role: 'Admin' },
              ]) && (
                <Menu.Item key="admin">
                  <Link to="/admin">{t('navigation.management')}</Link>
                </Menu.Item>
              )}
              {state.myAccount && state.myAccount.organizations.length > 1 && (
                <SubMenu
                  title={
                    <span>
                      <Icon type="user" />{' '}
                      {humanizeKey(activeOrg.substring(activeOrg.indexOf(':') + 1))}
                    </span>
                  }
                  key="orgs"
                >
                  {state.myAccount.organizations.length > 1 &&
                    state.myAccount.organizations.map(o => {
                      const { data } = organizationResource.read().find(x => x.id === o.id);
                      return (
                        <Menu.Item
                          key={o.id}
                          style={getActiveOrganization() === o.id ? { fontWeight: 500 } : null}
                          onClick={() => {
                            setActiveOrganization(o.id);
                            window.location.replace('');
                            window.location.reload();
                          }}
                        >
                          {data.identifier}
                        </Menu.Item>
                      );
                    })}
                </SubMenu>
              )}
              {/* <SubMenu key="language" title={<Icon type="global" />}>
                {menuItems(i18n)}
              </SubMenu> */}
              <Menu.Item key="uitloggen" onClick={state.keycloak.logout}>
                <Icon type="logout" />
              </Menu.Item>
            </Menu>
          ) : (
            <Menu mode="horizontal" className={styles.navMenu} selectedKeys={selectedKeys}>
              <Menu.Item key="inloggen" onClick={state.keycloak.login}>
                <Icon type="login" />
              </Menu.Item>
            </Menu>
          )}
        </Col>
      </Row>
    </Container>
  );
};
