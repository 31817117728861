import React from 'react';
import { Accent } from 'Components';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { humanizeKey } from 'Utility';
import { AntFormItem } from './AntFormItem';

export const AntArray = props => {
  const { title, required, className, items, canAdd, onAddClick } = props;
  const { t } = useTranslation();
  return (
    <AntFormItem {...props}>
      <div className={className}>
        {items &&
          items.map(element => (
            <>
              <Accent type="div">
                {(t(`form.${title}_single`), humanizeKey(title))} {required ? '*' : null}
              </Accent>
              <div key={element.key} className={element.className} style={{ display: 'flex' }}>
                <div>{element.children}</div>
                <Button
                  type="danger"
                  shape="round"
                  onClick={element.onDropIndexClick(element.index)}
                  icon="delete"
                  style={{ marginLeft: 'auto', alignSelf: 'center' }}
                />
              </div>
            </>
          ))}

        {canAdd && (
          <Button
            type="secondary"
            shape="round"
            onClick={onAddClick}
            style={{ marginLeft: 'auto' }}
            icon="plus"
          >
            Toevoegen
          </Button>
        )}
      </div>
    </AntFormItem>
  );
};
