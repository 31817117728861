import React, { useEffect, useState, useCallback } from 'react';
import useReactRouter from 'use-react-router';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { Accent, SchemaButton } from 'Components';
import { Spin, Table, Button, notification, Popconfirm } from 'antd';

import { collectionResource } from 'Resources';
import { getActiveOrganization, humanizeKey, shorten } from 'Utility';
import { isObject } from 'react-jsonschema-form/lib/utils';
import ButtonGroup from 'antd/lib/button/button-group';
import { getEntries, addEntry, deleteEntry, correctEntry } from './Adapter';

export const Collections = () => {
  const { t } = useTranslation();
  const collectionResources = collectionResource.read();
  const {
    match: { params },
  } = useReactRouter();

  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState({});
  const [editingEntry, setEditingEntry] = useState();
  const [visible, setVisible] = useState(false);

  const [collectionEntries, setCollectionEntries] = useState();

  // TODO get from router
  const id = params.collection;
  const selectedCollection = collectionResources.find(c => c.id === id);

  const handleCorrect = entry => {
    setEditingEntry(entry);
    setFormState(entry.data);
    setVisible(true);
    return Promise.resolve();
  };

  const handleDelete = entry => {
    return deleteEntry(entry.collectionId, entry.id).then(r => {
      setCollectionEntries(collectionEntries.filter(e => e.id !== entry.id));
      notification.success({
        message: t('handleAct.delete.title', 'Deleted entry'),
      });
    });
  };

  const handleSubmit = (collectionId, formData) => {
    setLoading(true);
    if (editingEntry) {
      correctEntry(collectionId, editingEntry.id, formData)
        .then(r => {
          setCollectionEntries(collectionEntries.map(e => (r.data.id === e.id ? r.data : e)));
          setVisible(false);
          setFormState({});
          notification.success({
            message: t('handleAct.correct.title', 'Entry successfully corrected'),
          });
        })
        .finally(() => {
          setEditingEntry(null);
          setLoading(false);
        });
    } else {
      addEntry(collectionId, formData)
        .then(r => {
          setCollectionEntries([...collectionEntries, r.data]);
          setVisible(false);
          setFormState({});
          notification.success({
            message: t('handleAct.success.title'),
          });
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    getEntries(id).then(r => setCollectionEntries(r.data));
  }, [params.collection]);

  if (!selectedCollection || !collectionEntries) {
    return <Spin />;
  }

  return (
    <>
      <Accent type="h1">{t(`entities.${id}_plural`, humanizeKey(id))}</Accent>
      <CollectionTable
        entries={collectionEntries}
        collection={selectedCollection}
        onDelete={handleDelete}
        onCorrect={handleCorrect}
      />
      <SchemaButton
        loading={loading}
        config={selectedCollection}
        onSubmit={handleSubmit}
        formState={formState}
        setFormState={setFormState}
        visible={visible}
        setVisible={setVisible}
      />
    </>
  );
};

const CollectionTable = ({ collection, entries, onDelete, onCorrect }) => {
  const { t } = useTranslation();
  const activeOrg = getActiveOrganization();

  const columns = [
    {
      key: 'id',
      title: 'ID',
      dataIndex: 'id',
      render: text => shorten(text),
    },
    ...Object.entries(entries.length === 0 ? {} : entries[0].data)
      .filter(([k, v]) => !isObject(v))
      .map(([k, v]) => ({
        key: k,
        title: t(`form.${k}`),
        dataIndex: `data.${k}`,
      })),
    {
      key: 'action',
      title: 'Action',
      render: (text, record) =>
        record.owner === activeOrg && (
          <EntryActions entry={record} onDelete={onDelete} onCorrect={onCorrect} />
        ),
    },
  ];
  columns.push({
    title: '',
    dataIndex: '',
    render: (text, record) =>
      !!collection.registrationConfigs.length && (
        <Link to={`/collections/${collection.id}/${record.id}`}>
          <Button icon="eye" type="primary" />
        </Link>
      ),
  });

  return (
    <Table
      rowKey={r => r.id}
      dataSource={entries}
      columns={columns}
      pagination={{ defaultPageSize: 20 }}
    />
  );
};

const EntryActions = ({ entry, onDelete, onCorrect }) => {
  const [loadingCorrect, setLoadingCorrect] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const handleDelete = () => {
    setLoadingDelete(true);
    onDelete(entry).finally(() => setLoadingDelete(false));
  };

  const handleCorrect = () => {
    setLoadingCorrect(true);
    onCorrect(entry).finally(() => setLoadingCorrect(false));
  };

  return (
    <>
      <ButtonGroup>
        <Button loading={loadingCorrect} enabled={!loadingDelete} onClick={handleCorrect}>
          Correct
        </Button>
        <Popconfirm title="Weet u het zeker?" onConfirm={handleDelete}>
          <Button loading={loadingDelete} enabled={!loadingCorrect} type="danger">
            Delete
          </Button>
        </Popconfirm>
      </ButtonGroup>
    </>
  );
};
