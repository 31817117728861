import React from 'react';
import { InputNumber } from 'antd';
import { AntFormItem } from './AntFormItem';

export const AntInputNumber = props => {
  const { schema, value, required, onChange } = props;

  return (
    <AntFormItem {...props}>
      <InputNumber
        value={value}
        required={required}
        max={schema.maximum}
        min={schema.minimum}
        onChange={onChange}
      />
    </AntFormItem>
  );
};
