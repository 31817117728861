import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ContextStore, isAuthorizedFun } from 'Components';

export const ProtectedRoute = ({ component, path, roles, redirectPath, ...rest }) => {
  const { state } = useContext(ContextStore);

  if (roles && roles.length && !isAuthorizedFun(state.myAccount, roles)) {
    return (
      <Redirect
        from={path}
        to={{
          pathname: redirectPath || '/',
        }}
      />
    );
  }

  return <Route path={path} {...rest} component={component} />;
};
