/* eslint-disable react/destructuring-assignment */
import React, { useContext, useState } from 'react';
import { Accent, AssetName, ContextStore } from 'Components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { notification, Row } from 'antd';
import { CollectionEntry } from 'Pages';
import { GetAssetData } from './batch';
import { getAssetConfigsOfType } from './assets';
import { getActiveOrganization } from './helpers';

const settings = {
  overrides: {
    Silo: {
      listingTitle: ({ batch }) => {
        return `Silo ${batch.data.silo_nummer}`;
      },
      batchCardTitle: ({ batch }) => {
        // eslint-disable-next-line react/destructuring-assignment
        return <span>{`Silo ${batch.data.silo_nummer}`}</span>;
      },
      batchDetailTitle: ({ batch }) => {
        return `Silo ${batch.data.silo_nummer}`;
      },
    },
    Vleeskuiken: {
      batchCardDetails: ({ batch }) => {
        return (
          <span>
            <b>Afdelingscode: </b> {batch.data.afdelingscode}
          </span>
        );
      },
    },
    Ouderdier: {
      batchCardDetails: ({ batch }) => {
        return (
          <span>
            <b>Stal: </b> {batch.data.algemeen.stal}
          </span>
        );
      },
    },
    KuijpersEi: {
      batchCardTitle: ({ batch }) => {
        // eslint-disable-next-line react/destructuring-assignment
        return (
          <>
            {batch.amount}{' '}
            <Accent>{batch.status?.type === 'broeden' ? 'Bebroede eieren' : 'Eieren'}</Accent>{' '}
            Rolcontainer
          </>
        );
      },
      batchDetailTitle: props => settings.overrides.KuijpersEi.batchCardTitle(props),
      batchCardDetails: ({ batch }) => {
        if (batch.status.type !== 'broeden') return null;

        return (
          <span>
            <b>Inzet broedmachine: </b>{' '}
            {moment(
              batch.actRegistrations.find(r => r.type === 'broedEiRegistration').data.inzet_datum
            ).fromNow()}
          </span>
        );
      },
    },
    ExternEi: {
      batchCardTitle: ({ batch }) => {
        // eslint-disable-next-line react/destructuring-assignment
        return (
          <>
            {batch.amount}{' '}
            <Accent>
              {batch.status?.type === 'broeden' ? 'Bebroede eieren (Extern)' : 'Eieren (Extern)'}
            </Accent>{' '}
            Rolcontainer
          </>
        );
      },
      batchDetailTitle: props => settings.overrides.ExternEi.batchCardTitle(props),
      batchCardDetails: props => settings.overrides.KuijpersEi.batchCardDetails(props),
    },
    Concentraat: {
      listingTitle: ({ batch, assets }) => {
        return (
          <>
            {moment(batch.createdAt).format('L')}
            {' - '}
            {batch.amount} KG <small>(voor silo {batch.data.silo_nummer})</small>
          </>
        );
      },
      batchCardDetails: ({ batch }) => {
        return (
          <span>
            <b>Voercode: </b> <small>{batch.data.voercode}</small>
            <br />
            <b>Voor silo: </b> {batch.data.silo_nummer}
          </span>
        );
      },
    },
    KloekeKip: {
      batchCardTitle: ({ batch, assets }) => {
        // eslint-disable-next-line react/destructuring-assignment
        const assetData = GetAssetData(assets, batch.assetId);

        return (
          <>
            {batch.amount}{' '}
            <Accent>
              {assetData} {batch.data.soort}
            </Accent>{' '}
            <AssetName assetId={batch.assetId} />
          </>
        );
      },
      batchDetailTitle: props => settings.overrides.KloekeKip.batchCardTitle(props),
      batchCardDetails: ({ batch }) => {
        return (
          <span>
            <b>Kwaliteit: </b> {batch.data.kwaliteit}
            <br />
            <b>Klasse: </b> {batch.data.klasse}
          </span>
        );
      },
    },
  },
  default: {
    listingTitle: ({ batch, assets }) => {
      return (
        <>
          {moment(batch.createdAt).format('L')}
          {' - '}
          <BatchCardTitle batch={batch} assets={assets} />
        </>
      );
    },
    batchCardTitle: ({ batch, assets }) => {
      const assetData = GetAssetData(assets, batch.assetId);

      return (
        <>
          {batch.amount} <Accent>{assetData}</Accent> <AssetName assetId={batch.assetId} />
        </>
      );
    },
    batchDetailTitle: ({ batch, assets, t }) => {
      const batchDate = moment(batch.createdAt).format('DD MMMM YYYY');
      return (
        <>
          <Accent>
            {batch.amount} {GetAssetData(assets, batch.assetId)}
          </Accent>{' '}
          <AssetName assetId={batch.assetId} /> {t('batch.from')} {batchDate}
        </>
      );
    },
  },
  acts: {
    overrides: {
      stienenProductionRegistration: {
        isHiddenFromTimeline: true,
      },
      broedEiRegistration: {
        shouldShowAct: (account, _act, batches) => {
          return (
            getActiveOrganization() === 'organizations:broederij' &&
            batches.some(
              x =>
                x.amount > 0 &&
                (x.assetId === 'ExternEi' || x.assetId === 'KuijpersEi') &&
                x.status.type !== 'broeden'
            )
          );
        },
        inputs0: {
          acceptBatch: x => x.amount > 0 && x.status.type !== 'broeden',
        },
      },
      createOuderdierBatch: {
        validateActInput: (myBatches, actInput) => {
          if (
            myBatches.some(
              b =>
                b.amount > 0 &&
                b.assetId === 'Ouderdier' &&
                b.data.algemeen.stal === actInput.output0.algemeen.stal
            )
          ) {
            notification.error({
              message: `Er bestaat al een ouderdier koppel met stal: ${actInput.output0.algemeen.stal}`,
            });
            return false;
          }

          return true;
        },
      },
      createVleeskuiken: {
        validateActInput: (myBatches, actInput) => {
          if (
            myBatches.some(
              b =>
                b.amount > 0 &&
                b.assetId === 'Vleeskuiken' &&
                b.data.afdelingscode === actInput.output0.afdelingscode
            )
          ) {
            notification.error({
              message: `Er bestaat al een vleeskuiken koppel met afdelingscode: ${actInput.output0.afdelingscode}`,
            });
            return false;
          }

          return true;
        },
        shouldShowAct: (account, _act, batches) => {
          return (
            getActiveOrganization() === 'organizations:kuiken' &&
            batches.some(
              x =>
                x.amount > 0 &&
                (x.assetId === 'ExternEi' || x.assetId === 'KuijpersEi') &&
                x.status.type === 'broeden'
            )
          );
        },
        inputs0: {
          acceptBatch: x => x.amount > 0 && x.status.type === 'broeden',
        },
      },
    },
    default: {
      acceptBatch: x => x.amount > 0,
    },
  },
};

export const shouldCollectionAdminPageBeVisible = (activeOrg, collection) => {
  if (collection.id === 'voercodes') {
    if (activeOrg === 'organizations:vitelia') return true;
  } else if (activeOrg === 'organizations:kuiken') {
    return true;
  }

  return false;
};

export const compareBatchesInGroup = (groupedAssetConfig, batch1, batch2) => {
  if (groupedAssetConfig.id === 'Silo') {
    return batch1.data.silo_nummer - batch2.data.silo_nummer;
  }

  if (groupedAssetConfig.id === 'Ouderdier') {
    return batch1.data.algemeen.stal - batch2.data.algemeen.stal;
  }

  if (groupedAssetConfig.id === 'Vleeskuiken') {
    return batch1.data.afdelingscode - batch2.data.afdelingscode;
  }

  if (groupedAssetConfig.id === 'Ei') {
    return (
      moment(batch2.data.legdatum || batch2.data.datum) -
      moment(batch1.data.legdatum || batch1.data.datum)
    );
  }

  return batch1.createdAt - batch2.createdAt;
};

export const isActHiddenFromTimeline = actId => {
  const isHidden = settings.acts.overrides[actId]?.isHiddenFromTimeline;
  return isHidden ?? false;
};

export const isActInputValid = (act, myBatches, actInput) => {
  const validateFn = settings.acts.overrides[act.id]?.validateActInput;
  return validateFn ? validateFn(myBatches, actInput) : true;
};

export const filterInputs = (act, batches, inputIndex) => {
  const acceptFn =
    settings.acts.overrides[act.id]?.[`inputs${inputIndex}`]?.acceptBatch ||
    settings.acts.default.acceptBatch;
  return batches.filter(acceptFn);
};

export const shouldShowAct = (account, act, batches) => {
  const checkFn = settings.acts.overrides[act.id]?.shouldShowAct;
  return checkFn ? checkFn(account, act, batches) : true;
};

export const BatchCardTitle = ({ batch, assets }) => {
  const { t } = useTranslation();
  const assetConfigId = assets.find(x => x.id === batch.assetId).configId;
  const props = { batch, assets, t };

  return (
    settings.overrides[assetConfigId]?.batchCardTitle?.(props) ||
    settings.default.batchCardTitle(props)
  );
};

export const BatchCardDetails = ({ batch, assets }) => {
  const { t } = useTranslation();
  const assetConfigId = assets.find(x => x.id === batch.assetId).configId;
  const props = { batch, assets, t };

  return settings.overrides[assetConfigId]?.batchCardDetails?.(props) || null;
};

export const BatchDetailTitle = ({ batch, assets }) => {
  const { t } = useTranslation();
  const assetConfigId = assets.find(x => x.id === batch.assetId).configId;
  const props = { batch, assets, t };

  return (
    settings.overrides[assetConfigId]?.batchDetailTitle?.(props) ||
    settings.default.batchDetailTitle(props)
  );
};

export const BatchListingTitle = ({ batch, assets }) => {
  const { t } = useTranslation();
  const assetConfigId = assets.find(x => x.id === batch.assetId).configId;
  const props = { batch, assets, t };

  return (
    settings.overrides[assetConfigId]?.listingTitle?.(props) || settings.default.listingTitle(props)
  );
};
