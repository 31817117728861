import React, { useContext } from 'react';
import useReactRouter from 'use-react-router';

import { Link, Route, Switch } from 'react-router-dom';

import { Row, Col, Menu, Affix, Card } from 'antd';
import { Container, isAuthorizedFun, ContextStore } from 'Components';
import { useTranslation } from 'react-i18next';
import { collectionResource } from 'Resources';
import { ProtectedRoute } from 'Utility/protected-route';
import { getActiveOrganization, humanizeKey } from 'Utility';
import { shouldCollectionAdminPageBeVisible } from 'Utility/uiSettings';
import { Organization } from './Organization';
import { Collections } from './Collections';
import { Invite } from './Invite';
import { Products } from './Products';
import { Landing } from './Landing';

const hiddenCollections = ['organizations'];

export const Admin = () => {
  const { match, location } = useReactRouter();
  const { t } = useTranslation();
  const { state } = useContext(ContextStore);

  const activeOrganization = getActiveOrganization();

  return (
    <Container>
      <Row>
        <Col span={4}>
          <Affix offsetTop={100}>
            <Menu
              selectedKeys={[location.pathname]}
              mode="inline"
              style={{ background: 'transparent', border: 'none', textAlign: 'right' }}
            >
              {isAuthorizedFun(state.myAccount, [{ type: 'account', role: 'User' }]) && (
                <Menu.ItemGroup title={t('menu.supplychain')} style={{ marginBottom: 30 }}>
                  {collectionResource
                    .read()
                    .filter(x => !hiddenCollections.includes(x.id))
                    .filter(x => shouldCollectionAdminPageBeVisible(activeOrganization, x))
                    .map(c => (
                      <Menu.Item key={`/admin/collections/${c.id}`}>
                        <Link to={`/admin/collections/${c.id}`}>
                          {t(`entities.${c.id}`, humanizeKey(c.id), { count: 2 })}
                        </Link>
                      </Menu.Item>
                    ))}
                </Menu.ItemGroup>
              )}
              {window.ACCOUNTS_API && (
                <Menu.ItemGroup title={t('menu.organization')}>
                  {/* {isAuthorizedFun(state.myAccount, [{ type: 'account', role: 'User' }]) && (
                  <Menu.Item key="/admin/organization">
                    <Link to="/admin/organization">{t('organization.profile')}</Link>
                  </Menu.Item>
                )} */}
                  <Menu.Item key="/admin/invite">
                    <Link to="/admin/invite">{t('organization.invite')}</Link>
                  </Menu.Item>
                </Menu.ItemGroup>
              )}
            </Menu>
          </Affix>
        </Col>
        <Col span={20}>
          <Card bordered={false}>
            <Switch>
              <ProtectedRoute
                roles={[{ type: 'account', role: 'User' }]}
                path={`${match.path}/organization`}
                component={Organization}
              />
              <ProtectedRoute
                roles={[{ type: 'account', role: 'User' }]}
                exact
                path={`${match.path}/collections/:collection`}
                component={Collections}
              />
              <ProtectedRoute
                roles={[
                  { type: 'account', role: 'Admin' },
                  { type: 'organization', role: 'Admin' },
                ]}
                path={`${match.path}/invite`}
                component={Invite}
              />
              {/* <ProtectedRoute
                roles={[
                  { type: 'account', role: 'User' },
                  { type: 'organization', role: 'Admin' },
                ]}
                path={`${match.path}/products`}
                component={Products}
              /> */}
              <Route component={Landing} />
            </Switch>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
