import React, { useContext } from 'react';
import { ContextStore } from 'Components/Store/Store';

export const AssetName = ({ assetId }) => {
  const {
    state: { assets },
  } = useContext(ContextStore);

  const asset = assets.find(x => x.id === assetId);
  return asset ? asset.data.identifier : null;
};

export const AssetNameFunc = assetId => {
  const {
    state: { assets },
  } = useContext(ContextStore);

  const asset = assets.find(x => x.id === assetId);
  return asset ? asset.data.identifier : null;
};
