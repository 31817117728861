import React, { useEffect, useState, useContext } from 'react';
import { Container, ContextStore } from 'Components';
import { Spin } from 'antd';
import { getActsForInputs } from 'Utility/Adapter';
import { GetAsset, isAssetConfigOfType } from 'Utility';
import { getBatch } from './Adapter';

const FilterTransport = ['broodstock', 'egg', 'smolt', 'salmon'];

export const BatchContainer = ({ id, children, notFound, handleShowLoading = true }) => {
  const [batch, setBatch] = useState(null);
  const [loading, setLoading] = useState(true);
  const [acts, setActs] = useState([]);

  const {
    state: { assets, acts: allActs },
  } = useContext(ContextStore);

  useEffect(() => {
    setLoading(true);
    getBatch(id)
      .then(res => setBatch(res.data))
      .finally(() => setLoading(false));
  }, [id]);

  // should re-trigger getActsForInputs if batch has changed, since it may be allowed to execute different acts
  // note: setBatch method gets passed down to child component and is called after an act has been executed.
  useEffect(() => {
    setLoading(true);
    getActsForInputs([id])
      .then(r => setActs(r.data))
      .finally(() => setLoading(false));
  }, [id, batch]);

  const assetData = batch && GetAsset(assets, batch.assetId);

  if (handleShowLoading && loading) {
    return <Spin size="large" />;
  }

  if (batch || !handleShowLoading) {
    const filteredActs = acts.filter(x => {
      if (x.id === 'transport' && FilterTransport.includes(assetData.configId)) return false;
      return true;
    });

    const actsWithMultiInput = allActs.filter(
      x =>
        x.inputs.length > 1 && isAssetConfigOfType(assets, batch.assetId, x.inputs[0].assetConfigId)
    );

    return React.Children.map(children, child =>
      React.cloneElement(child, {
        batch,
        setBatch,
        acts: [...filteredActs, ...actsWithMultiInput],
        loading,
      })
    );
  }

  return (
    <Container>
      <p>{notFound}</p>
    </Container>
  );
};
