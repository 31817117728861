import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import { AssetName } from 'Components';
import { GetAssetData } from 'Utility';

export const ReportPieChart = ({ report, piechart, batches }) => {
  const transformedData = report.transform ? report.transform(batches) : batches;
  const pieData = piechart.mapToData(transformedData);

  return pieData && pieData.length ? (
    <div style={{ height: 300 }}>
      <ResponsivePie
        data={pieData}
        tooltip={piechart.tooltip}
        radialLabel="label"
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
        colors={{ scheme: 'nivo' }}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        radialLabelsSkipAngle={10}
        radialLabelsTextXOffset={6}
        radialLabelsTextColor="#333333"
        radialLabelsLinkOffset={0}
        radialLabelsLinkDiagonalLength={16}
        radialLabelsLinkHorizontalLength={24}
        radialLabelsLinkStrokeWidth={1}
        radialLabelsLinkColor={{ from: 'color' }}
        slicesLabelsSkipAngle={10}
        slicesLabelsTextColor="#333333"
        animate
        motionStiffness={90}
        motionDamping={15}
      />
    </div>
  ) : null;
};
