import { supplychainAxios } from 'Utility/api';

// GET /batches/{id}
export const getBatch = batchId => supplychainAxios.get(`/batches/${batchId}`);

// GET /batches/{id}/children
export const getBatchChildren = batchId => supplychainAxios.get(`/batches/${batchId}/children`);

export const getBatchForwardHierarchy = batchId =>
  supplychainAxios.get(`/batches/hierarchy/forward/${batchId}`);
