import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Card, Row, Col, Icon, Badge, Tag } from 'antd';
import { Accent, AssetName, ContextStore } from 'Components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { GetAssetData } from 'Utility';
import { BatchCardDetails, BatchCardTitle } from 'Utility/uiSettings';
import styles from './BatchCard.module.scss';

export const BatchCard = ({ batch, colSpan = 8 }) => {
  const { t } = useTranslation();
  const {
    state: { assets },
  } = useContext(ContextStore);

  return (
    <Col span={colSpan}>
      <Link to={`/detail/${batch.id}`}>
        <Card bordered={false} className={styles.card}>
          <Row type="flex">
            <Col span={24}>
              <h2 className={styles.title}>
                <BatchCardTitle assets={assets} batch={batch} />
              </h2>
              <Row style={{ paddingBottom: 5 }}>
                {batch.status && batch.status.type === 'Pending' && <Badge count={t('pending')} />}
                {Object.entries(batch.inventory).map(([k, v]) => (
                  <Tag key={k}>{`${v} ${t(`inventory.${k}`, k)}`}</Tag>
                ))}
              </Row>
              <Row style={{ paddingBottom: 5 }}>
                <BatchCardDetails assets={assets} batch={batch} />
              </Row>
            </Col>
            <Col span={12}>
              {batch.data.kenmerk}
              {moment(
                batch.data.datum ||
                  batch.data.legdatum ||
                  batch.data.algemeen?.opzetdag ||
                  batch.createdAt
              ).format('LL')}
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <div className={styles.more}>
                {t('more')}
                <span className={styles.arrow}>
                  <Icon type="caret-right" />
                </span>
              </div>
            </Col>
          </Row>
        </Card>
      </Link>
    </Col>
  );
};
