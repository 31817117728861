import React, { useEffect, useContext, useState } from 'react';

import { Container, ContextStore, Accent, ActButton } from 'Components';
import { Row, Col, Tabs, Button, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { filterByObject } from 'Utility';
import produce from 'immer';
import { getActsForInputs, getAssets } from 'Utility/Adapter';
import { shouldShowAct } from 'Utility/uiSettings';
import { getBatches } from './Adapter';
import { InternalOverview } from './InternalOverview';

export const Overview = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(ContextStore);
  const [viewState, setViewState] = useState('grid');
  const [filteredData, setFilteredData] = useState([]);
  const [overviewActs, setOverviewActs] = useState([]);

  const { acts } = state;

  const actsWithArrayInputs = acts.filter(act => act.inputs.some(i => i.type === 'array'));

  useEffect(() => {
    let mounted = true;
    getBatches().then(r => {
      if (mounted) dispatch({ type: 'setBatches', batches: r.data });
    });
    getActsForInputs([]).then(r => {
      if (mounted) setOverviewActs(r.data);
    });
    getAssets().then(x => {
      if (mounted)
        dispatch({
          type: 'setAssets',
          group: 'assets',
          data: x.data,
        });
    });

    return () => {
      mounted = false;
      setOverviewActs([]);
    };
  }, []);

  useEffect(() => {
    setFilteredData(state.batches);
  }, [state.batches]);

  const filterBatchesByAssets = assetIds => {
    setFilteredData(
      state.batches.filter(b => assetIds.length === 0 || assetIds.includes(b.assetId))
    );
  };

  return (
    <Container>
      <Row style={{ paddingLeft: 20, paddingRight: 20 }}>
        <h1>
          <Accent type="span">Welkom, </Accent>
          {state.user.firstName || state.user.username}!
        </h1>
      </Row>
      <Row style={{ paddingLeft: 20, paddingRight: 20 }}>
        <Accent type="h3">{t('overview')}</Accent>
      </Row>
      <Row style={{ paddingLeft: 20, paddingRight: 20 }}>
        <Col span={8} style={{ marginBottom: '2rem' }}>
          <Select
            key="form"
            mode="multiple"
            style={{ width: 400 }}
            placeholder="Filter op type"
            values={state.batches}
            onChange={filterBatchesByAssets}
          >
            {state.batches
              .map(b =>
                state.assets.length && state.assets.find(a => a.id === b.assetId)
                  ? state.assets.find(a => a.id === b.assetId)
                  : false
              )
              .filter((value, index, self) => self.indexOf(value) === index)
              .map(
                asset =>
                  asset && <Select.Option key={asset.id}>{asset.data.identifier}</Select.Option>
              )}
          </Select>
        </Col>
        <Col span={16}>
          <Row type="flex" gutter={20} style={{ float: 'right' }}>
            <Col>
              <Button
                shape="round"
                onClick={() => setViewState(viewState === 'grid' ? 'table' : 'grid')}
              >
                {t('show')} {viewState === 'grid' ? t('table') : t('cards')}
              </Button>
            </Col>
            <Col>
              <Row style={{ marginTop: '1rem', marginBottom: '2rem' }} type="flex" gutter={10}>
                {overviewActs.map(act => (
                  <Col key={act.id}>
                    <ActButton act={act} />
                  </Col>
                ))}
                {actsWithArrayInputs.map(act => {
                  return (
                    shouldShowAct(state.myAccount, act, state.myBatches) && (
                      <Col key={act.id}>
                        <ActButton act={act} />
                      </Col>
                    )
                  );
                })}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ paddingLeft: 20, paddingRight: 20 }}>
        <InternalOverview viewState={viewState} filteredData={filteredData} />
      </Row>
    </Container>
  );
};
