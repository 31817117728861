import React, { useState, useEffect, useContext } from 'react';
import useReactRouter from 'use-react-router';

import { useTranslation } from 'react-i18next';
import { Accent, ContextStore, Organization } from 'Components';
import { Form, Button, Input, Icon, Select, notification } from 'antd';
import { listOrganizationRoles, postInvite } from './Adapter';

export const Invite = () => {
  const { t } = useTranslation();
  const { match } = useReactRouter();

  return (
    <>
      <Accent type="h1">{t('Uitnodigen', { id: match.params.collectie })}</Accent>
      <WrappedInviteForm />
    </>
  );
};

let id = 1;

const InviteForm = ({ form }) => {
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const {
    state: { organizations, myAccount },
  } = useContext(ContextStore);

  const { getFieldDecorator, getFieldValue } = form;
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };

  const invitableOrganizations = myAccount.roles.includes('Admin')
    ? organizations
    : myAccount.organizations.filter(o => o.roles.includes('Admin'));

  useEffect(() => {
    listOrganizationRoles().then(r => setRoles(r.data));
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        setLoading(true);
        postInvite(values)
          .then(() => {
            notification.success({
              message: t('invite.added.title'),
              description: t('invite.added.description'),
            });
            form.resetFields();
          })
          .finally(() => setLoading(false));
      }
    });
  };

  const addOrg = () => {
    // can use data-binding to get
    const keys = form.getFieldValue('keys');
    const nextKeys = keys.concat(id++);
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      keys: nextKeys,
    });
  };

  const removeOrg = k => {
    const keys = form.getFieldValue('keys');
    if (keys.length === 1) return;

    form.setFieldsValue({
      keys: keys.filter(key => key !== k),
    });
  };
  const firstOrg = {
    span: 20,
    offset: 0,
  };
  const notFirstOrg = {
    span: 20,
    offset: 4,
  };

  getFieldDecorator('keys', { initialValue: [0] });
  const orgKeys = getFieldValue('keys');

  const orgRoles = orgKeys.map(k => (
    <Form.Item
      label={orgKeys.indexOf(k) === 0 ? 'Organisaties' : null}
      wrapperCol={orgKeys.indexOf(k) !== 0 ? notFirstOrg : firstOrg}
      key={`organizations[${k}].label`}
      style={{ marginBottom: 0 }}
      required
    >
      <Form.Item
        wrapperCol={{ span: 24 }}
        required
        key={`organizations[${k}].id`}
        style={{ display: 'inline-block', width: '45%', marginRight: '10px' }}
      >
        {getFieldDecorator(`organizations[${k}].id`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              required: true,
              whitespace: true,
            },
          ],
        })(
          <Select placeholder="Selecteer een organisatie" allowClear>
            {invitableOrganizations.map(o => (
              <Select.Option key={o.id}>
                <Organization id={o.id} />
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item
        wrapperCol={{ span: 24 }}
        required
        key={`organizations[${k}]`}
        style={{ display: 'inline-block', width: '45%', marginRight: '10px' }}
      >
        {getFieldDecorator(`organizations[${k}].roles`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              type: 'array',
              required: true,
            },
          ],
        })(
          <Select mode="multiple" placeholder="Selecteer een rol">
            {roles.map(r => (
              <Select.Option key={r.id}>{r.name}</Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>
      {orgKeys.length > 1 ? (
        <Icon
          className="dynamic-delete-button"
          type="minus-circle-o"
          onClick={() => removeOrg(k)}
        />
      ) : null}
    </Form.Item>
  ));

  return (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      <Form.Item label="E-mail">
        {getFieldDecorator('email', {
          rules: [
            {
              type: 'email',
            },
            {
              required: true,
            },
          ],
        })(<Input />)}
      </Form.Item>

      <Form.Item label="Voornaam">
        {getFieldDecorator('firstName', {
          rules: [{ required: true, whitespace: true }],
        })(<Input />)}
      </Form.Item>
      <Form.Item label="Achternaam">
        {getFieldDecorator('lastName', {
          rules: [{ required: true, whitespace: true }],
        })(<Input />)}
      </Form.Item>
      {orgRoles}

      <Form.Item wrapperCol={{ span: 20, offset: 4 }}>
        <Button type="dashed" onClick={addOrg} style={{ width: '60%' }}>
          <Icon type="plus" /> Voeg organisatie toe
        </Button>
      </Form.Item>

      <Form.Item wrapperCol={{ span: 20, offset: 4 }}>
        <Button type="primary" htmlType="submit" loading={loading}>
          Uitnodigen
        </Button>
      </Form.Item>
    </Form>
  );
};
const WrappedInviteForm = Form.create()(InviteForm);
