import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { Accent, Bold } from 'Components';
import { Form, Button, Input, notification, Row, Col, Spin } from 'antd';
import { getActiveOrganization } from 'Utility';
import { getOrganization, updateOrganization } from './Adapter';

export const Organization = () => {
  const { t } = useTranslation();
  const [editState, setEditState] = useState(false);
  const [loading, setLoading] = useState(false);

  const [organization, setOrganization] = useState();

  useEffect(() => {
    getOrganization(getActiveOrganization()).then(r => setOrganization(r.data));
  }, []);

  const OrganizationProfile = () => {
    if (!organization) return <Spin />;

    const { name, kvk, skal, email, city, website, coordinates } = organization.profile;

    return (
      <Row>
        <Accent type="h1">{name}</Accent>
        <Col span={12}>
          <Row>
            <Col span={6}>
              <Bold>{t('kvk')}</Bold>
            </Col>
            <Col span={18}>{kvk}</Col>
          </Row>
          <Row>
            <Col span={6}>
              <Bold>{t('city')}</Bold>
            </Col>
            <Col span={18}>{city}</Col>
          </Row>
          <Row>
            <Col span={6}>
              <Bold>{t('email')}</Bold>
            </Col>
            <Col span={18}>{email}</Col>
          </Row>
          <Row>
            <Col span={6}>
              <Bold>{t('website')}</Bold>
            </Col>
            <Col span={18}>{website}</Col>
          </Row>
          <Row>
            <Col span={6}>
              <Bold>{t('skal')}</Bold>
            </Col>
            <Col span={18}>{skal}</Col>
          </Row>
          <Row>
            <Col span={6}>
              <Bold>{t('coordinates')}</Bold>
            </Col>
            <Col span={18}>
              {coordinates ? (
                <>
                  {coordinates.latitude}-{coordinates.longitude}
                </>
              ) : (
                'Er zijn geen coordinaten beschikbaar'
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const OrganizationForm = ({ form }) => {
    const { getFieldDecorator } = form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
    };

    const cancelEdit = () => {
      form.resetFields();
      setEditState(false);
    };

    const handleSubmit = e => {
      e.preventDefault();
      form.validateFields((err, values) => {
        if (!err) {
          setLoading(true);
          updateOrganization(values)
            .then(r => {
              notification.success({
                message: t('notifications.updatedOrganization.title'),
                description: t('notifications.updatedOrganization.description'),
              });
              setOrganization(r.data);
              setEditState(false);
              form.resetFields();
            })
            .finally(() => setLoading(false));
        }
      });
    };

    return (
      <Form {...formItemLayout} onSubmit={handleSubmit}>
        <Form.Item label="KVK">
          {getFieldDecorator('kvk', {
            initialValue: organization.profile.kvk,
            rules: [
              {
                required: true,
                message: 'Vul een kvk nummer in!',
              },
              {
                len: 8,
                message: 'Een KVK nummer bestaat uit 8 tekens',
              },
            ],
          })(<Input placeholder="kvk" />)}
        </Form.Item>
        <Form.Item label="Bedrijfsnaam">
          {getFieldDecorator('name', {
            initialValue: organization.data.identifier,
            rules: [
              {
                required: true,
                message: 'Bedrijfsnaam is verplicht!',
              },
            ],
          })(<Input placeholder="Bedrijfsnaam" />)}
        </Form.Item>

        <Form.Item label="Plaats">
          {getFieldDecorator('city', {
            initialValue: organization.profile.city,
            rules: [
              {
                required: true,
                message: 'Plaats is verplicht!',
              },
            ],
          })(<Input placeholder="Plaats" />)}
        </Form.Item>

        <Form.Item label="E-mail">
          {getFieldDecorator('email', {
            initialValue: organization.profile.email,
            rules: [
              {
                type: 'email',
              },
            ],
          })(<Input placeholder="bedrijf@domein.nl" />)}
        </Form.Item>
        <Form.Item label="Website">
          {getFieldDecorator('website', {
            initialValue: organization.profile.website,
            rules: [
              {
                type: 'url',
                message: 'Vul de gehele webite in, inclusief http(s)://',
              },
            ],
          })(<Input placeholder="domein.nl" />)}
        </Form.Item>

        <Form.Item label="SKAL nummer">
          {getFieldDecorator('skal', {
            initialValue: organization.profile.skal,
            rules: [],
          })(<Input placeholder="SKAL nummer" />)}
        </Form.Item>
        <Form.Item label="Coordinaten">
          <Input.Group compact>
            <Form.Item labelCol={0} wrapperCol={24}>
              {getFieldDecorator('coordinates.latitude', {
                initialValue:
                  organization.profile.coordinates &&
                  organization.profile.coordinates.latitude.toString(),
                rules: [
                  {
                    pattern: '^-?[0-9]{1,3}(?:.[0-9]{1,10})?$',
                    message: 'Must be a coordinate',
                  },
                ],
              })(<Input addonBefore="Latitude" />)}
            </Form.Item>
            <Form.Item labelCol={0} wrapperCol={24}>
              {getFieldDecorator('coordinates.longitude', {
                initialValue:
                  organization.profile.coordinates &&
                  organization.profile.coordinates.longitude.toString(),
                rules: [
                  {
                    pattern: '^-?[0-9]{1,3}(?:.[0-9]{1,10})?$',
                    message: 'Must be a coordinate',
                  },
                ],
              })(<Input addonBefore="Longitude" />)}
            </Form.Item>
          </Input.Group>
        </Form.Item>

        <Form.Item wrapperCol={{ span: 20, offset: 4 }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Opslaan
          </Button>
          <Button type="link" onClick={cancelEdit}>
            Annuleren
          </Button>
        </Form.Item>
      </Form>
    );
  };
  const WrappedOrganizationForm = Form.create()(OrganizationForm);

  return (
    <>
      {editState ? (
        <WrappedOrganizationForm />
      ) : (
        <>
          <OrganizationProfile />
          <Button onClick={() => setEditState(true)} style={{ marginTop: 20 }}>
            Bewerk mijn organisatie
          </Button>
        </>
      )}
    </>
  );
};
