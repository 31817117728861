import React, { useState } from 'react';
import useReactRouter from 'use-react-router';

import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { ActModal } from 'Components';
import { humanizeKey } from 'Utility';

export const ActButton = ({ act, setBatch, setPending, batch, ...props }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const { history } = useReactRouter();

  const onClick = () => {
    setVisible(true);
  };

  const onSubmit = () => {
    if (setPending) setPending(true);
  };

  const onSuccess = result => {
    if (batch) {
      const modifiedBatch = result.inputs.find(b => b.id === batch.id);
      if (modifiedBatch) {
        if (batch.legalOwner !== modifiedBatch.legalOwner) {
          // redirect;
          history.push('/');
        }

        if (setBatch) setBatch(modifiedBatch);
      }
    }

    if (setPending) setPending(false);
  };

  const onFailure = () => setPending && setPending(false);

  return (
    <>
      <Button
        type="primary"
        shape="round"
        onClick={onClick}
        style={{ marginBottom: 10 }}
        {...props}
      >
        {t(`${act.id}.title`, humanizeKey(act.id), { batch })}
      </Button>
      <ActModal
        act={act}
        inputBatches={batch ? [batch] : []}
        visible={visible}
        setVisible={setVisible}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        onFailure={onFailure}
      />
    </>
  );
};
