import React from 'react';
import { Accent } from 'Components/Markup';
import { Input } from 'antd';
import { AntFormItem } from './AntFormItem';

export const AntReadOnly = props => {
  const { schema, value, required, onChange } = props;
  return (
    <AntFormItem {...props}>
      <Input
        readOnly
        disabled
        value={value}
        required={required}
        onChange={event => onChange(event.target.value)}
      />
    </AntFormItem>
  );
};
