import React from 'react';
import { Col, Table } from 'antd';

import { useTranslation } from 'react-i18next';

export const ReportTable = ({ batches, report }) => {
  const { t } = useTranslation();

  const columnsWithTranslations = report.table.columns.map(column => {
    if (column.title) {
      return column;
    }

    return {
      ...column,
      title: t(`report.${report.id}.${column.key}`),
    };
  });

  return (
    <Col span={24}>
      <Table
        rowKey={r => r.assetId}
        columns={columnsWithTranslations}
        dataSource={report.transform ? report.transform(batches) : batches}
      />
    </Col>
  );
};
