import React, { useState, useContext } from 'react';
import moment from 'moment';
// import 'moment/locale/nl';

import { Row, Col, Button, Drawer, Tabs, Empty, Tag } from 'antd';
import { useTranslation } from 'react-i18next';

import { Accent, Container, ActButton, AssetName, ContextStore } from 'Components';
import { GetAssetData } from 'Utility';
import { BatchDetailTitle } from 'Utility/uiSettings';
import { Registrations, RegistrationTable } from './Registrations';
import { BatchInfo } from './BatchInfo';
import { BatchContainer } from './BatchContainer';
import { BatchTracing } from './BatchTracing';

import { BatchHierarchy } from '../Hierarchy/BatchHierarchy';
import { BatchHierachyContainer } from '../Hierarchy/BatchHierachyContainer';

const { TabPane } = Tabs;

export const DetailWrapper = ({
  match: {
    params: { id },
  },
}) => {
  return (
    <BatchContainer id={id}>
      <Detail />
    </BatchContainer>
  );
};

export const Detail = ({ batch, setBatch, acts = [] }) => {
  const [pending, setPending] = useState(false);
  const [tracingDrawerVisible, setTracingDrawerVisible] = useState(false);

  const { t } = useTranslation();

  const onTracingDrawerClose = () => {
    setTracingDrawerVisible(false);
  };

  const onTracincDrawerOpen = () => {
    setTracingDrawerVisible(true);
  };

  const {
    state: { assets },
  } = useContext(ContextStore);

  return (
    <Container>
      <Row type="flex">
        <Col span={20}>
          <h1>
            <BatchDetailTitle assets={assets} batch={batch} />
          </h1>
        </Col>
        <Col style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }} span={4}>
          <Button onClick={onTracincDrawerOpen}>Trace</Button>
          {acts.length > 0 && acts.find(a => a.id === 'archiveren') && (
            <ActButton
              type="danger"
              batch={batch}
              act={acts.find(a => a.id === 'archiveren')}
              setPending={setPending}
              setBatch={setBatch}
              style={{ marginLeft: 10 }}
            />
          )}
          <Drawer
            title="Tracing"
            placement="bottom"
            height={750}
            closable
            onClose={onTracingDrawerClose}
            visible={tracingDrawerVisible}
            destroyOnClose
          >
            <Tabs>
              <TabPane key="a" tab={t('trace.forward')}>
                <BatchTracing batch={batch} />
              </TabPane>
              <TabPane key="b" tab={t('trace.backward')}>
                <BatchHierachyContainer getActs={false} id={batch.id}>
                  <BatchHierarchy showBatchDetail={false} showRegistrations />
                </BatchHierachyContainer>
              </TabPane>
            </Tabs>
          </Drawer>
        </Col>
        {Object.keys(batch.inventory).length > 0 && (
          <Col>
            {Object.entries(batch.inventory).map(([k, v]) => (
              <Tag color="#393e47" key={k} style={{ fontSize: 14 }}>
                {`${v} ${t(`inventory.${k}`, k)}`}
              </Tag>
            ))}
          </Col>
        )}
        <Col span={24}>
          <Row style={{ marginTop: '1rem', marginBottom: '2rem' }} type="flex" gutter={10}>
            {acts
              .filter(a => a.id !== 'archiveren')
              .map(act => (
                <Col key={act.id}>
                  <ActButton batch={batch} act={act} setPending={setPending} setBatch={setBatch} />
                </Col>
              ))}
          </Row>
        </Col>
      </Row>

      <Row>
        <Tabs>
          <TabPane key="detail" tab="Batch details">
            <Row>
              <Col span={12}>
                <BatchInfo batch={batch} />
              </Col>
              <Col span={12}>
                <Registrations batch={batch} pending={pending} />
              </Col>
            </Row>
          </TabPane>
          <TabPane key="registrations" tab="Registratie overzicht">
            <RegistrationTable
              batch={batch}
              registrations={batch.actRegistrations.filter(r => Object.keys(r.data).length > 0)}
            />
          </TabPane>
        </Tabs>
      </Row>

      <Row style={{ marginTop: '4rem' }} />
    </Container>
  );
};
