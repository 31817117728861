import { supplychainAxios } from 'Utility/api';

export const getOrganizations = () => supplychainAxios.get('/collections/organizations/entries');
export const getAssetConfigs = () => supplychainAxios.get('/assetconfigs');
export const getCollections = () => supplychainAxios.get('/collections');

export const handleAct = (act, batchIds = [], outState, formData) =>
  supplychainAxios.post('/supplychain/', {
    id: act.id,
    inputBatchIds: batchIds,
    outputAssetIds: outState,
    data: formData,
  });
