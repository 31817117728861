import React, { useState, useContext, useEffect } from 'react';
import { notification, Modal, Select, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { listChildren } from 'Utility';
import { CustomForm, ContextStore, Accent } from 'Components';
import { getSchemaForAsset, addAsset } from 'Pages/Beheer/Adapter';
import { getAssetConfigs } from 'Utility/Adapter';

export const AssetModal = ({ configId, visible, setVisible, onSubmit, onSuccess }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState({});
  const [assetSchema, setAssetSchema] = useState({});
  const [selectedAssetConfigId, setAssetConfigId] = useState(null);
  const { dispatch } = useContext(ContextStore);
  const [assetConfigs, setAssetConfigs] = useState();

  useEffect(() => {
    (async () => setAssetConfigs((await getAssetConfigs())?.data))();
  }, []);

  const submitAddAsset = async ({ formData }) => {
    setLoading(true);

    addAsset(selectedAssetConfigId, formData)
      .then(r => {
        notification.success({
          message: t('addAsset.success.title'),
          description: t('addAsset.success.description'),
        });
        setFormState({});

        if (onSuccess) onSuccess(r.data);
      })
      .finally(() => {
        setVisible(false);
        setLoading(false);
      });
  };

  const clearState = () => {
    setVisible(false);
    setFormState({});
  };

  useEffect(() => {
    setLoading(true);
    if (selectedAssetConfigId)
      getSchemaForAsset(selectedAssetConfigId)
        .then(r => setAssetSchema(r.data))
        .finally(() => setLoading(false));
  }, [selectedAssetConfigId]);

  return (
    <>
      <Modal visible={visible} onCancel={clearState} footer={false}>
        <Accent type="h3">{t('addAsset.title')}</Accent>
        <Row style={{ paddingBottom: 20 }}>
          <Select
            value={selectedAssetConfigId}
            style={{ width: 400 }}
            placeholder={t('selectProduct')}
            onChange={e => {
              setAssetConfigId(e);
              setFormState({});
            }}
          >
            {assetConfigs &&
              assetConfigs
                .filter(config => listChildren(assetConfigs, config).length === 0)
                .map(x => <Select.Option key={x.id}>{t(`asset.${x.id}`, x.id)}</Select.Option>)}
          </Select>
        </Row>

        {Object.entries(assetSchema).length > 0 && (
          <Row>
            <CustomForm
              config={configId}
              loading={loading}
              jsonschema={assetSchema}
              onSubmit={submitAddAsset}
              onCancel={clearState}
              formState={formState}
              setFormState={setFormState}
            />
          </Row>
        )}
      </Modal>
    </>
  );
};
